import { Image } from "@chakra-ui/image";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import icon from "assets/ebet-logo.png";
import React from "react";
import { useLayoutContext } from "../../context/LayoutContext";
import { useUserContext } from "../../context/UserContext";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

export const AppTitleV2 = () => {
  const { isDesktop } = useLayoutContext();
  const { user } = useUserContext();

  return (
    <Box flex="1">
      <Heading
        ml={isDesktop ? "0" : "2"}
        fontSize="20px"
        lineHeight="70px"
        color="#fff"
      >
        {isDesktop && (
          <Box position="relative">
            <HStack>
              <Box id="topbar-logo">
                <Box>
                  <Image
                    src={icon}
                    alt="ebet logo"
                    zIndex="2"
                    width="120px"
                    height="43px"
                  />
                </Box>
              </Box>
              <Box id="topbar-breadcrumb">
                <Breadcrumbs />
              </Box>
            </HStack>
          </Box>
        )}
        {!isDesktop && <>ebet AdminIT</>}
      </Heading>
    </Box>
  );
};
