import React from "react";
import { Form, FormikErrors, FormikProps, withFormik } from "formik";
import { HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { AdminApiClient } from "../api/adminApiClient";
import { CherryHubAdminApi } from "../api/adminApiModels";
import { ABNLookupField } from "../components/fields/ABNLookupField/ABNLookupField";
import { FormStack } from "../components/FormStack/FormStack";

interface NewBusinessFormValues {
  abnRecord: CherryHubAdminApi.ABNRecord | null;
}

interface NewBusinessFormProps {
  apiClient: AdminApiClient;
  onCancel: () => void;
  onSuccess?: () => void;
  onFailure?: (message?: string) => void;
  firstInputRef?: React.MutableRefObject<any>;
}

const InnerForm = ({
  isSubmitting,
  isValidating,
  isValid,
  onCancel,
  values,
  firstInputRef,
}: NewBusinessFormProps & FormikProps<NewBusinessFormValues>) => {
  return (
    <FormStack>
      <ABNLookupField
        name="abnRecord"
        label="Australian Business Number"
        searchInputRef={firstInputRef}
      />

      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button
          isLoading={isSubmitting || isValidating}
          colorScheme="cherryButton"
          color="#fff"
          type="submit"
          disabled={isSubmitting || !isValid || values.abnRecord === null}
        >
          Save
        </Button>
        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

export const NewBusinessForm = withFormik<
  NewBusinessFormProps,
  NewBusinessFormValues
>({
  mapPropsToValues: (props) => {
    return {
      abnRecord: null,
    };
  },

  handleSubmit: async (values, { props, setFieldError }) => {
    if (values.abnRecord === null) {
      setFieldError("abnRecord", "ABN is required.");
      return;
    }

    const result = await props.apiClient.createBusiness(values.abnRecord);

    if (result.ok) {
      if (props.onSuccess) {
        props.onSuccess();
      }
    } else {
      if (props.onFailure) {
        props.onFailure(
          result.message ?? "Business creation failed with an unkown error."
        );
      }
    }
  },

  validateOnMount: false,
  validateOnBlur: false,

  validate: async (values, props) => {
    let errors: FormikErrors<NewBusinessFormValues> = {};

    // if (values.abnRecord !== null) {
    //   const result = await props.apiClient.getBusiness(values.abnRecord.ABN);
    //   if (result.ok && result.data?.BusinessId === values.abnRecord?.ABN) {
    //     errors.abnRecord =
    //       "This ABN has already been registered with Cherry Hub.";
    //   }
    // }

    return errors;
  },
})(InnerForm);
