import { Button } from "@chakra-ui/react";
import React from "react";

export const EditButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      colorScheme="cherryButton"
      size="sm"
      width="51px"
      height="26px"
      fontSize="12px"
      fontFamily="Oswald"
      fontWeight={500}
      onClick={onClick}
    >
      EDIT
    </Button>
  );
};
