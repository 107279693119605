import { Button } from "@chakra-ui/button";
import { HStack } from "@chakra-ui/layout";
import { Auth, signInWithEmailAndPassword, User } from "firebase/auth";
import { FormikProps, withFormik } from "formik";
import React from "react";
import { TextField } from "../components/fields/TextField/TextField";
import { FormStack } from "../components/FormStack/FormStack";
import { isEmptyStr } from "../util/stringHelper";

interface LoginFormValues {
  email: string;
  password: string;
}

interface LoginFormProps {
  onSuccess?: (user: User) => void;
  onError?: (message: string) => void;
  firebaseAuth: Auth;
}

const InnerForm = ({
  isSubmitting,
  isValid,
}: LoginFormProps & FormikProps<LoginFormValues>) => {
  return (
    <FormStack>
      <TextField name="email" label="Email" placeholder="Email Address" />
      <TextField
        name="password"
        type="password"
        label="Password"
        placeholder="Password"
      />

      <HStack width="100%" justifyContent="center" spacing="3" pt="4">
        <Button
          isLoading={isSubmitting}
          colorScheme="cherryButton"
          color="#fff"
          type="submit"
          disabled={isSubmitting || !isValid}
          paddingLeft="4"
          paddingRight="4"
          width="100px"
        >
          Next
        </Button>
      </HStack>
    </FormStack>
  );
};
type FirebaseLoginResult =
  | { ok: true; user: User }
  | { ok: false; errorCode: string; errorMessage: string };
type T = { [key: string]: number };

// Maps firebase error codes to login form validation messages.
const firebaseErrorCodeMap: {
  [key: string]: Partial<{ [P in keyof LoginFormValues]: string }>;
} = {
  "auth/invalid-email": { email: "Invalid email address." },
  "auth/user-not-found": { email: "User not found." },
  "auth/user-disabled": { email: "This account has been disabled." },
  "auth/wrong-password": { password: "Invalid password" },
  "auth/too-many-requests": { password: "Too many attempts. Try again later." },
};

const firebaseLogin = (
  auth: Auth,
  email: string,
  password: string
): Promise<FirebaseLoginResult> => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        resolve({ ok: true, user: result.user });
      })
      .catch((error) => {
        resolve({
          ok: false,
          errorCode: error.code,
          errorMessage: error.message,
        });
      });
  });
};

export const LoginForm = withFormik<LoginFormProps, LoginFormValues>({
  mapPropsToValues: (props) => {
    return {
      email: "",
      password: "",
    };
  },

  handleSubmit: async (values, { props, setFieldError, setErrors }) => {
    let isInvalid = false;
    if (isEmptyStr(values.email)) {
      setFieldError("email", "Email is required");
      isInvalid = true;
      return;
    }

    if (isEmptyStr(values.password)) {
      setFieldError("password", "Password is required");
      isInvalid = true;
      return;
    }

    const loginResult = await firebaseLogin(
      props.firebaseAuth,
      values.email,
      values.password
    );

    if (!loginResult.ok) {
      // Map firebase error codes to validation messages.
      const formErrors = firebaseErrorCodeMap[loginResult.errorCode];

      if (formErrors) {
        setErrors(formErrors);
      } else {
        console.error(`Firebase login error: ${loginResult.errorMessage}`);
        if (props.onError) {
          props.onError("Login error.");
        }
      }
    } else {
      if (props.onSuccess) {
        props.onSuccess(loginResult.user);
      }
    }
  },

  validateOnMount: false,
  validateOnBlur: true,

  validate: (values) => {},
})(InnerForm);
