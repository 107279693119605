import React, { useContext, useEffect, useMemo, useState } from "react";
import { createContext } from "react";
import { useParams } from "react-router";
import { CherryHubAdminApi } from "../api/adminApiModels";
import { ErrorMessage } from "../components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { ApiRequestState, useAdminApiRequest } from "../hooks/useApiRequest";
import { useAdminApiClient } from "../hooks/useApiClient";
import { useFirebase } from "./FirebaseContext";
import { useUserContext } from "./UserContext";
import { useNavigate, useLocation } from "react-router-dom";

interface TProfileContext {
  profile: any;
}

const ProfileContext = createContext<TProfileContext | null>(null);

/**
 * Loads models from the api based on the presence of react-router path parameters. Example: if
 * the `businessId` path parameter is set, this component will display loading & error states and
 * then inject the business into the context once loaded.
 */
export const ProfileContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const userCtx = useUserContext();
  const apiClient = userCtx.adminApiClient;

  const { auth } = useFirebase();
  const navigate = useNavigate();
  const location = useLocation();
  const [profile, setProfile] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const load = async () => {
    try {
      const profile = await apiClient?.getProfile();
      if (
        profile?.data?.isPasswordTemporary &&
        location.pathname.indexOf("/change-password") === -1
      ) {
        navigate("/admin/change-password");
      }
      setProfile(profile);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, [apiClient]);

  useEffect(() => {}, [profile]);

  const context = useMemo(
    () => ({
      profile: profile,
    }),
    [profile]
  );

  if (!profile && userCtx.user) {
    return <LoadingIndicator color="cherryUi.600" />;
  }

  if (error) {
    return (
      <ErrorMessage>
        An error was encounterd while loading the page
      </ErrorMessage>
    );
  }

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

/**
 * Returns the profile loaded from the `:businessId` path parameter
 */
export const useProfile = (): any => {
  const ctx = useContext(ProfileContext);
  // console.log(ctx, "ctx");
  // if (!ctx || !ctx.business.data) {
  //   // throw new Error("Missing business context");
  //   return null;
  // }

  return ctx?.profile?.data;
};
