import React from "react";
import {
  ChakraProvider,
  StorageManager as ChakraStorageManager,
} from "@chakra-ui/react";
import { theme } from "../../styles/chakraTheme";

// Forces Chakra to always use light mode
const colorModeManager: ChakraStorageManager = {
  get() {
    return "light";
  },
  set(value: string) {},
  type: "cookie",
};

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <>
    <ChakraProvider theme={theme} colorModeManager={colorModeManager}>
      {children}
    </ChakraProvider>
  </>
);
