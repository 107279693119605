import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { CopyText } from "../../components/Utilities/CopyText";
import { useBusiness } from "../../context/BusinessContext";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useSearchInput } from "../../hooks/useSearchInput";
import { useToast } from "../../hooks/useToast";
import BusinessUtil from "../../util/BusinessUtil";
import DateUtil from "../../util/DateUtil";

export const ApikeyDetail = () => {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const contextBusiness = useBusiness();
  const { debouncedQuery: lookupText, element: lookupInputElement } =
    useSearchInput({
      placeholder: "Start typing...",
    });

  const { debouncedQuery: abnText, element: abnInputElement } = useSearchInput({
    placeholder: "Start typing...",
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const apiClient = useAdminApiClient();
  const [formData, setFormData] = useState<any>({
    whitelist: "0.0.0.0",
    title: "",
    accessList: [],
    // dummy
    // srcVenue: {
    //   Id: 34,
    //   Name: "Royal Venue 4",
    // },
  });
  const [businesses, setBusinesses] = useState<any>([]);
  const [abnRecords, setAbnRecords] = useState<any>([]);
  const [currentUser, setCurrentUser] = useState<any>();
  const [venueRecords, setVenueRecords] = useState<any>([]);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStepLoading, setIsStepLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { apikeyId: originalApikeyId } = useParams();
  const apikeyId: any = useMemo(() => {
    return originalApikeyId === "new" ? null : originalApikeyId;
  }, [originalApikeyId]);

  const load = async (skipLoader = false) => {
    if (!skipLoader) {
      setIsLoading(true);
    }
    const singleVenue = await apiClient.getSingleApikey(
      apikeyId?.toString() ?? ""
    );
    setCurrentUser(singleVenue.data);
    setIsLoading(false);
    setIsSubmitted(false);
  };

  const areAllValid = () => {
    return formData.title && formData.accessList.length;
  };

  const onConfirm = (msg: string, callback: any) => {
    if (confirm(msg)) {
      callback();
    }
  };

  const updateForm = useCallback(
    (newData) => {
      setFormData({ ...formData, ...newData });
    },
    [formData]
  );

  const getLookupVenues = async () => {
    setIsStepLoading(true);
    if (!lookupText) {
      setVenueRecords([]);
      return;
    }
    const records = await apiClient.getLookupVenues(lookupText);
    setVenueRecords(records.data);
    setIsStepLoading(false);
  };

  const renderLoader = () => {
    return (
      isStepLoading && (
        <Box>
          <LoadingIndicator color="cherryUi.600" />
        </Box>
      )
    );
  };

  const toggleRole = () => {
    const ask =
      currentUser.meta.role === "Admin"
        ? "Are you sure you want to change this user's role to Staff?"
        : "Are you sure you want to change this user's role to Admin?";
    onConfirm(ask, async () => {
      setIsSubmitted(true);
      const result = await apiClient.toggleRole(apikeyId);
      load(true);
    });
  };

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    if (!apikeyId) {
      const result = await apiClient.createApikey(formData);
      setIsLoading(true);

      if (result.status >= 200 && result.status <= 299) {
        navigate(
          BusinessUtil.getUrl(contextBusiness, `/apikeys/${result.data._id}`)
        );
        showSuccessToast("User successfully added.");
      } else {
        setIsLoading(false);
        let errorDesc = "";
        try {
          if (result.data.error === "exists") {
            errorDesc = "That user already exists";
          }
          showErrorToast(errorDesc);
        } catch (e) {
          showErrorToast("An unknown error occurred");
        }
      }
    } else {
      const result = await apiClient.modifyVenue(apikeyId, formData);
      navigate(BusinessUtil.getUrl(contextBusiness, `/venues`));
    }
    setIsSubmitted(false);
  }, [apikeyId, formData]);

  const onCancel = () => {
    navigate(BusinessUtil.getUrl(contextBusiness, "/apikeys"));
  };

  useEffect(() => {
    if (apikeyId && contextBusiness) {
      load();
    }
  }, [apikeyId, contextBusiness]);

  useEffect(() => {
    if (contextBusiness) {
      updateForm({
        role: contextBusiness?.isSystemRecord ? "Global" : "Staff",
      });
    }
  }, [contextBusiness]);

  useEffect(() => {
    if (apikeyId) {
      if (currentUser) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [currentUser, apikeyId]);

  if (isLoading || !contextBusiness) {
    return (
      <Box>
        <LoadingIndicator color="cherryUi.600" />
      </Box>
    );
  }

  return (
    <>
      <PageHeading>
        <PageHeading.Title>
          {currentUser ? currentUser.title : "New API Key"}
        </PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <VStack width="100%" spacing="4" alignItems="start">
            {apikeyId && currentUser && (
              <Box w="100%">
                <Box
                  py={5}
                  px={4}
                  bg="#fdf4e1"
                  borderRadius="10px"
                  mb={4}
                  border="1px solid #aaa"
                >
                  <strong>API Key:</strong>

                  <CopyText
                    text={currentUser.key}
                    hover={true}
                    copyOnIcon={true}
                  >
                    <Text fontWeight="strong">{currentUser.key}</Text>
                  </CopyText>
                </Box>
                <Text fontSize="md" mb={4}>
                  <strong>Created On:</strong>{" "}
                  {DateUtil.getLocalDateFormatFromString(currentUser.createdAt)}
                  <br />
                  <strong>Whitelist:</strong>{" "}
                  {currentUser.whitelist ?? "0.0.0.0 (open for all)"}
                  <br />
                  <strong>Access:</strong> {currentUser.accessList.join(", ")}
                </Text>
                <Divider />
              </Box>
            )}

            {/* creation form */}
            {!apikeyId && (
              <Box w="100%" maxW="600px">
                <FormControl w="100%">
                  <FormLabel>Title (internal purposes only)</FormLabel>
                  <Input
                    w="100%"
                    type="text"
                    placeholder=""
                    onChange={(e) => updateForm({ title: e.target.value })}
                    value={formData.firstName}
                  />
                </FormControl>

                <FormControl w="100%" mt={4}>
                  <FormLabel>Whitelist Details</FormLabel>
                  <Input
                    w="100%"
                    type="text"
                    onChange={(e) => updateForm({ whitelist: e.target.value })}
                    placeholder=""
                    value={formData.firstName}
                  />
                  <FormHelperText>
                    Can enter a single IP address, or a range. Examples:
                    <ul style={{ marginLeft: "30px" }}>
                      <li>
                        Single IP: <strong>172.1.1.1</strong>
                      </li>
                      <li>
                        Range: <strong>172.1.1.1 - 172.1.1.10</strong>
                      </li>
                    </ul>
                    <strong>Note</strong>: entering 0.0.0.0 will open it for all
                    IP addresses.
                  </FormHelperText>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Module Access</FormLabel>
                  <Select
                    options={[
                      "Businesses",
                      "Venues",
                      "Payments",
                      "Members",
                      "Settings",
                    ].map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    isMulti={true}
                    onChange={(e) =>
                      updateForm({ accessList: e.map((i) => i.value) })
                    }
                  />
                </FormControl>
              </Box>
            )}

            {/* generic step loader */}
            {renderLoader()}
            <Box my="20px"></Box>
            <Box>
              {!apikeyId && (
                <HStack>
                  <Box>
                    <Button
                      colorScheme="cherryButton"
                      type="submit"
                      isLoading={isSubmitted}
                      disabled={isStepLoading || !areAllValid()}
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </Box>

                  <Box>
                    <Button disabled={isStepLoading} onClick={onCancel}>
                      Cancel
                    </Button>
                  </Box>
                </HStack>
              )}

              {apikeyId && currentUser && !currentUser.isSystemRecord && (
                <HStack>
                  <Box>
                    <Button disabled={isStepLoading} onClick={onCancel}>
                      Back
                    </Button>
                  </Box>
                </HStack>
              )}
            </Box>
          </VStack>
        </Card>
        {error != null && (
          <ErrorMessage>
            An error was encountered while searching businesses.
          </ErrorMessage>
        )}
      </PageContent>
    </>
  );
};
