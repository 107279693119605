import React from "react";
import { Alert, AlertIcon } from "@chakra-ui/alert";

export const ErrorMessage = ({ children }: { children: React.ReactNode }) => {
  return (
    <Alert status="error">
      <AlertIcon />
      {children}
    </Alert>
  );
};
