import { debounce } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { useDidUnmount } from "./useDidUnmount";

export const useDebouncedValue = <T>(value: T, waitMs: number): T => {
  const didUnmount = useDidUnmount();
  const [state, setState] = useState<T>(value);

  const debouncedSetState = useCallback(
    debounce((value: T) => {
      // Prevent state updates after the component has unmounted.
      if (didUnmount.current) {
        return;
      }
      setState(value);
    }, waitMs),
    [didUnmount, setState, waitMs]
  );

  useEffect(() => {
    debouncedSetState(value);
  }, [value, debouncedSetState]);

  return state;
};
