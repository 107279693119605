import {
  query,
  where,
  collection,
  getCountFromServer,
} from "firebase/firestore";
import { Result } from "../util/result";

export const getKYCSummary = async (
  db: any,
  startDate: string,
  businessId: string
) => {
  try {
    const docsRef = collection(db, "references");
    const queryRef = query(
      docsRef,
      where("timestamp", ">=", new Date(`${startDate} 00:00:00`)),
      where("businessId", "==", businessId),
      where("status", "==", "Success")
    );
    const snapshot = await getCountFromServer(queryRef);
    const sum = snapshot.data().count;
    return new Result({ type: "success", data: sum });
  } catch (error) {
    return new Result({
      type: "fail",
      message: "Failed to get KYC summary",
      errors: error,
    });
  }
};
