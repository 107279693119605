import { useEffect, useRef } from "react";

/**
 * Side effect that runs when a modal is closed
 * @param modalState
 * @param fn
 * @param deps
 */
export const useModalCloseEffect = (
  modalState: boolean | { isOpen: boolean },
  fn: () => void,
  deps: any[]
) => {
  const modalIsOpen =
    typeof modalState === "object" ? modalState.isOpen : modalState;
  const previous = useRef<boolean>();

  useEffect(() => {
    if (previous.current === true && modalIsOpen === false) {
      fn();
    }
    previous.current = modalIsOpen;
  }, [modalIsOpen, ...deps, previous]);
};
