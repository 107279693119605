import React, { useEffect, useState } from "react";
import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Link as ChakraLink,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  Tag,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card/Card";
import { CopyText } from "../../../components/Utilities/CopyText";
import { useBusiness } from "../../../context/BusinessContext";
import { useAdminApiClient } from "../../../hooks/useApiClient";
import { useSearchInput } from "../../../hooks/useSearchInput";
import { PlusIcon } from "../../../styles/icons";
import BusinessUtil from "../../../util/BusinessUtil";
import { useToast } from "../../../hooks/useToast";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";

export const VenueTable = () => {
  const { debouncedQuery, element: searchInputElement } = useSearchInput({
    placeholder: "Search Venues...",
  });

  const contextBusiness = useBusiness();
  const navigate = useNavigate();
  const apiClient = useAdminApiClient();
  const { showSuccessToast } = useToast();
  const [records, setRecords] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  const load = async () => {
    try {
      const venues = await apiClient.getAllVenues(debouncedQuery);
      setRecords(venues.data.items);
      setIsLoading(false);
    } catch (_) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const onCopyUrl = (venueId: string) => {
    const url = `${LINKIT_APP_DOMAIN}/${contextBusiness.srcBusiness.id}/${venueId}`;
    navigator.clipboard.writeText(url);
    showSuccessToast("Linkit URL copied to clipboard!");
  };

  useEffect(() => {
    if (contextBusiness) {
      load();
    }
  }, [debouncedQuery, contextBusiness]);

  const skeletonLoadingTable = (rows: number = 12) => {
    return (
      <Tr>
        <Td width="15%">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="75%">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="10%" whiteSpace="nowrap">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="10%" whiteSpace="nowrap">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
      </Tr>
    );
  };

  return (
    <Box w="100%" marginTop="40px !important">
      <Text as="h2" className="page-section-title">
        VENUES
      </Text>
      <Text as="p" className="page-section-content" mt="14px">
        Select a venue to manage.
      </Text>
      <Spacer h={2} />

      {isError ? (
        <ErrorMessage>
          An error was encountered while loading the venues.
        </ErrorMessage>
      ) : (
        <Card
          width="100%"
          overflowX="auto"
          paddingX="0 !important"
          backgroundColor="transparent"
        >
          <VStack width="100%" spacing="4" alignItems="start">
            <HStack width="100%" justifyContent="space-between">
              <Box flex="1" maxW="400px">
                <Box width="100%">{searchInputElement}</Box>
              </Box>
              <Button
                leftIcon={<PlusIcon />}
                onClick={() =>
                  navigate(BusinessUtil.getUrl(contextBusiness, "/venues/new"))
                }
                colorScheme="cherryButton"
                className="primary-button"
              >
                ADD VENUE
              </Button>
            </HStack>
            <Table size="sm" className="primary-table">
              <Thead>
                <Tr>
                  <Th w="10%">Id</Th>
                  <Th w="20%">Venue</Th>
                  <Th>Modules</Th>
                  <Th w="20%">Contact</Th>
                  <Th w="10%">LinkIt URL</Th>
                </Tr>
              </Thead>
              <Tbody>
                {(isLoading || !contextBusiness) && skeletonLoadingTable()}
                {!isLoading && records && records.length === 0 && (
                  <Tr>
                    <Td colSpan={4} textAlign="center">
                      No venues found
                    </Td>
                  </Tr>
                )}
                {records &&
                  records.length > 0 &&
                  !isLoading &&
                  records?.map((record: any) => (
                    <Tr key={record._id}>
                      <Td title="Copy." w="10%">
                        <CopyText
                          text={record.srcVenue.id}
                          hover={true}
                          copyOnIcon={true}
                        >
                          <ChakraLink
                            as={Link}
                            to={BusinessUtil.getUrl(
                              contextBusiness,
                              `/venues/${record._id}`
                            )}
                          >
                            {record.srcVenue.id}
                          </ChakraLink>
                        </CopyText>
                      </Td>
                      <Td w="20%">
                        <ChakraLink
                          as={Link}
                          to={BusinessUtil.getUrl(
                            contextBusiness,
                            `/venues/${record._id}`
                          )}
                        >
                          {record?.title}
                        </ChakraLink>
                      </Td>
                      <Td whiteSpace="nowrap">
                        <HStack>
                          {record.isSystemRecord && (
                            <Tag size="md" bg="#f0f0f0" color="#000">
                              System
                            </Tag>
                          )}
                          {record.modules.map((moduleName: any) => (
                            <Tag
                              key={moduleName}
                              size="md"
                              bg="#f0f0f0"
                              color="#000"
                            >
                              {moduleName}
                            </Tag>
                          ))}
                        </HStack>
                      </Td>
                      <Td w="20%">
                        {record.contactName ? (
                          <Box lineHeight="20px">
                            {record.contactName} <br /> {record.contactEmail}{" "}
                            <br /> {record.contactPhone}
                          </Box>
                        ) : (
                          <Box>N/A</Box>
                        )}
                      </Td>
                      <Td w="10%">
                        <Tooltip label="Copy URL" placement="top">
                          <IconButton
                            aria-label="copy url"
                            icon={<CopyIcon />}
                            onClick={() => onCopyUrl(record.srcVenue.id)}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </VStack>
        </Card>
      )}
    </Box>
  );
};
