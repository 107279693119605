import { Button } from "@chakra-ui/react";
import React from "react";

type SaveButtonProps = {
  isLoading: boolean;
  disable: boolean;
  onClick: () => void;
};

export const SaveButton = ({
  isLoading,
  disable,
  onClick,
}: SaveButtonProps) => {
  return (
    <Button
      colorScheme="cherryButton"
      size="sm"
      width="120px"
      height="40px"
      fontSize="14px"
      fontFamily="Oswald"
      fontWeight={500}
      isLoading={isLoading}
      disabled={disable}
      onClick={onClick}
    >
      SAVE
    </Button>
  );
};
