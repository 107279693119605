export const isEmptyStr = (value: null | undefined | string) => {
  return value === undefined || value === null || value.trim() === "";
};

export const notJson = (value: null | undefined | string) => {
  if (!value) return true;
  return value.indexOf("[") == -1 && value.indexOf("{") == -1;
};

export const isStrongPassword = (value: null | undefined | string) => {
  const strength = /^(?=.*[A-Z])(?=.*[!@#$&*=])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

  return strength.test(value ?? "");
};
