import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Link as ChakraLink,
  SkeletonText,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  VStack,
  Tag,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NewBusinessModal } from "../../components/Business/NewBusinessModal";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { CopyText } from "../../components/Utilities/CopyText";
import { useBusiness } from "../../context/BusinessContext";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useModalCloseEffect } from "../../hooks/useModalCloseEffect";
import { useSearchInput } from "../../hooks/useSearchInput";
import { useToast } from "../../hooks/useToast";
import { PlusIcon } from "../../styles/icons";
import BusinessUtil from "../../util/BusinessUtil";

export const Venues = () => {
  const { debouncedQuery, element: searchInputElement } = useSearchInput({
    placeholder: "Search Venues...",
  });

  const newBusinessModal = useDisclosure();
  const contextBusiness = useBusiness();
  const navigate = useNavigate();
  const apiClient = useAdminApiClient();
  const { showSuccessToast } = useToast();
  const [records, setRecords] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const refresh = () => {
    load();
  };

  // Refresh the business list when the modal is closed.
  useModalCloseEffect(newBusinessModal, () => refresh(), [refresh]);

  const load = async () => {
    const allBusinesses = await apiClient.getAllVenues(debouncedQuery);
    setRecords(allBusinesses.data.items);
    setIsLoading(false);
  };

  const onCopyUrl = (venueId: string) => {
    const url = `${LINKIT_APP_DOMAIN}/${contextBusiness.srcBusiness.id}/${venueId}`;
    navigator.clipboard.writeText(url);
    showSuccessToast("Linkit URL copied to clipboard!");
  };

  useEffect(() => {
    if (contextBusiness) {
      load();
    }
  }, [debouncedQuery, contextBusiness]);

  const skeletonLoading = (rows: number = 12) => {
    return (
      <HStack width="100%" boxShadow="lg">
        <Box width="15%" padding="4" pr="0">
          <SkeletonText mt="4" noOfLines={rows} spacing="4" />
        </Box>
        <Box width="75%" padding="4" pr="0">
          <SkeletonText mt="4" noOfLines={rows} spacing="4" />
        </Box>
        <Box width="10%" pt="4" pb="4">
          <SkeletonText mt="4" noOfLines={rows} spacing="4" />
        </Box>
      </HStack>
    );
  };

  const skeletonLoadingTable = (rows: number = 12) => {
    return (
      <Tr>
        <Td width="15%">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="75%">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="10%" whiteSpace="nowrap">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="10%" whiteSpace="nowrap">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
      </Tr>
    );
  };

  return (
    <>
      <PageHeading>
        <PageHeading.Title>Venues</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%" overflowX="auto">
          <VStack width="100%" spacing="4" alignItems="start">
            <HStack width="100%" justifyItems="start">
              <Box flex="1">
                <Box width="100%">{searchInputElement}</Box>
              </Box>
              <Button
                leftIcon={<PlusIcon />}
                onClick={() =>
                  navigate(BusinessUtil.getUrl(contextBusiness, "/venues/new"))
                }
                colorScheme="cherryButton"
              >
                New Venue
              </Button>
            </HStack>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th w="10%">Id</Th>
                  <Th w="20%">Name</Th>
                  <Th>Modules</Th>
                  {/* <Th w="40%">Address</Th> */}
                  <Th w="20%">Contact</Th>
                  <Th w="10%">LinkIt URL</Th>
                </Tr>
              </Thead>
              <Tbody>
                {(isLoading || !contextBusiness) && skeletonLoadingTable()}
                {!isLoading && records && records.length === 0 && (
                  <Tr>
                    <Td colSpan={4} textAlign="center">
                      No venues found
                    </Td>
                  </Tr>
                )}
                {records &&
                  records.length > 0 &&
                  !isLoading &&
                  records?.map((record: any) => (
                    <Tr key={record._id}>
                      <Td title="Copy." w="10%">
                        <CopyText
                          text={record.srcVenue.id}
                          hover={true}
                          copyOnIcon={true}
                        >
                          <ChakraLink
                            as={Link}
                            to={BusinessUtil.getUrl(
                              contextBusiness,
                              `/venues/${record._id}`
                            )}
                          >
                            {record.srcVenue.id}
                          </ChakraLink>
                        </CopyText>
                      </Td>
                      <Td w="20%">
                        <ChakraLink
                          as={Link}
                          to={BusinessUtil.getUrl(
                            contextBusiness,
                            `/venues/${record._id}`
                          )}
                        >
                          {record.srcVenue.name}
                        </ChakraLink>
                      </Td>
                      <Td whiteSpace="nowrap">
                        <HStack>
                          {record.isSystemRecord && (
                            <Tag size="md" bg="#f0f0f0" color="#000">
                              System
                            </Tag>
                          )}
                          {record.modules.map((moduleName: any) => (
                            <Tag
                              key={moduleName}
                              size="md"
                              bg="#f0f0f0"
                              color="#000"
                            >
                              {moduleName}
                            </Tag>
                          ))}
                        </HStack>
                      </Td>
                      {/* <Td w="40%">{record.address.description}</Td> */}

                      <Td w="20%">
                        {record.contactName ? (
                          <Box lineHeight="20px">
                            {record.contactName} <br /> {record.contactEmail}{" "}
                            <br /> {record.contactPhone}
                          </Box>
                        ) : (
                          <Box>N/A</Box>
                        )}
                      </Td>
                      <Td w="10%">
                        <Tooltip label="Copy URL" placement="top">
                          <IconButton
                            aria-label="copy url"
                            icon={<CopyIcon />}
                            onClick={() => onCopyUrl(record.srcVenue.id)}
                          />
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </VStack>
        </Card>
        {error != null && (
          <ErrorMessage>An error was encountered while searching.</ErrorMessage>
        )}
      </PageContent>
      <NewBusinessModal {...newBusinessModal} />
    </>
  );
};
