import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
  FormControlProps,
  FormLabelProps,
} from "@chakra-ui/form-control";
import { StackProps, Tooltip, VStack } from "@chakra-ui/react";
import { Field, FieldProps, getIn } from "formik";
import React from "react";
import { InfoIcon } from "../../../styles/icons";

export interface BaseFieldProps {
  name: string;
  label: string;
  helperText?: string;
  id?: string;
  infoText?: string;
  horizontal?: boolean;
  fcProps?: FormControlProps;
  labelProps?: StackProps;
  valueProps?: StackProps;
}

export interface FieldWrapperProps extends BaseFieldProps {
  children: (props: FieldProps<any, any>) => JSX.Element;
}

/** Provides label and error message rendering */
export const FieldWrapper = ({
  name,
  label,
  id: idProp,
  helperText,
  infoText,
  horizontal,
  fcProps,
  labelProps,
  valueProps,
  children,
}: FieldWrapperProps) => {
  const id = idProp ?? `${name}-field`;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<any, any>) => (
        <FormControl
          isInvalid={!!getIn(fieldProps.form.errors, name)}
          display={horizontal ? "flex" : undefined}
          flexDir={horizontal ? "row" : undefined}
          {...fcProps}
        >
          <VStack
            w="100%"
            alignItems="flex-start"
            flex={horizontal ? 1 : undefined}
            pt={horizontal ? 2 : undefined}
            {...labelProps}
          >
            {infoText && (
              <FormLabel htmlFor={id} fontWeight="bold" color="cherryGrey.900">
                {label}{" "}
                <Tooltip label={infoText} placement="right">
                  <span>
                    <InfoIcon />
                  </span>
                </Tooltip>
              </FormLabel>
            )}
            {!infoText && (
              <FormLabel htmlFor={id} fontWeight="bold" color="cherryGrey.900">
                {label}
              </FormLabel>
            )}
            {!!helperText && (
              <FormHelperText marginBottom="2">{helperText}</FormHelperText>
            )}
          </VStack>
          <VStack
            w="100%"
            alignItems="flex-start"
            alignSelf="center"
            flex={horizontal ? 3 : undefined}
            {...valueProps}
          >
            {children(fieldProps)}
            <FormErrorMessage>
              {getIn(fieldProps.form.errors, name)}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </Field>
  );
};
