import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Switch,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Geosuggest from "react-geosuggest";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusiness } from "../../context/BusinessContext";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useSearchInput } from "../../hooks/useSearchInput";
import { useToast } from "../../hooks/useToast";
import BusinessUtil from "../../util/BusinessUtil";
import DateUtil from "../../util/DateUtil";
import { isValidEmail } from "../../util/isValidEmail";
import { isValidPhone } from "../../util/isValidPhone";
import { isEmptyStr } from "../../util/stringHelper";

export const AddVenue = () => {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const contextBusiness = useBusiness();
  const { debouncedQuery: lookupText, element: lookupInputElement } =
    useSearchInput({
      placeholder: "Start typing...",
    });

  const { debouncedQuery: abnText, element: abnInputElement } = useSearchInput({
    placeholder: "Start typing...",
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const apiClient = useAdminApiClient();
  const [formData, setFormData] = useState<any>({
    contact: "",
    modules: [],
    transactionFee: "",
    // dummy
    // srcVenue: {
    //   Id: 34,
    //   Name: "Royal Venue 4",
    // },
  });
  const [validationError, setValidationError] = useState<string>("");
  const [businesses, setBusinesses] = useState<any>([]);
  const [abnRecords, setAbnRecords] = useState<any>([]);
  const [currentVenue, setCurrentVenue] = useState<any>();
  const [venueRecords, setVenueRecords] = useState<any>([]);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStepLoading, setIsStepLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { venueId: originalVenueId } = useParams();
  const venueId: any = useMemo(() => {
    return originalVenueId === "new" ? null : originalVenueId;
  }, [originalVenueId]);

  const isEditable = () => {
    return !venueId || !formData.address;
  };

  const load = async () => {
    setIsLoading(true);
    const singleVenue = await apiClient.getSingleVenue(
      venueId?.toString() ?? ""
    );
    setCurrentVenue(singleVenue.data);
    setIsLoading(false);
  };

  const onSelectVenueRecord = async (record: any) => {
    updateForm({ srcVenue: record });
  };

  const onSuggestSelect = (suggest: any) => {
    if (!suggest) {
      return;
    }
    const { location, gmaps } = suggest;
    updateForm({
      address: suggest,
    });
  };

  const getAddressDisplay = (address: any) => {
    if (!address) return <></>;

    return (
      <Box bg="#444" color="#fff" px={3} py={2} borderRadius="8px">
        <Text fontWeight="bold">{address.gmaps?.name} </Text>
        <Text fontSize="14px">{address.gmaps?.formatted_address}</Text>
      </Box>
    );
  };

  const onConfirm = (msg: string, callback: any) => {
    if (confirm(msg)) {
      callback();
    }
  };

  const updateForm = useCallback(
    (newData) => {
      setFormData({ ...formData, ...newData });
    },
    [formData]
  );

  const getLookupVenues = async () => {
    setIsStepLoading(true);
    if (!lookupText) {
      setIsStepLoading(false);
      setVenueRecords([]);
      return;
    }
    const records = await apiClient.getLookupVenues(lookupText);
    setVenueRecords(records.data);
    setIsStepLoading(false);
  };

  const renderLoader = () => {
    return (
      isStepLoading && (
        <Box>
          <LoadingIndicator color="cherryUi.600" />
        </Box>
      )
    );
  };

  const onSubmit = useCallback(async () => {
    setValidationError("");
    if (isEmptyStr(formData.contactName) || formData.contactName.length < 2) {
      setValidationError("Please enter a valid name");
      return;
    }
    const isValidContactEmail = isValidEmail(formData.contactEmail);
    if (!isValidContactEmail) {
      setValidationError("Please enter a valid email address");
      return;
    }
    const isValidContactPhone = isValidPhone(formData.contactPhone);
    if (!isValidContactPhone) {
      setValidationError("Please enter a valid phone number");
      return;
    }
    console.log(
      "vvv",
      formData.isTransactionFeeEnabled,
      formData.transactionFee
    );
    if (formData.isTransactionFeeEnabled && !formData.transactionFee) {
      setValidationError("Transaction fee is required");
      return;
    }
    setIsSubmitted(true);

    if (!venueId) {
      const result = await apiClient.createVenue(formData);
      setIsLoading(true);

      if (result.status >= 200 && result.status <= 299) {
        navigate(BusinessUtil.getUrl(contextBusiness, ""));
        showSuccessToast("Venue successfully enabled.");
      } else {
        setIsLoading(false);
        let errorDesc = "";
        try {
          if (result.data.error === "exists") {
            errorDesc = "That venue already exists";
          }
          showErrorToast(errorDesc);
        } catch (e) {
          showErrorToast("An unknown error occurred");
        }
      }
    } else {
      const result = await apiClient.modifyVenue(venueId, formData);
      navigate(BusinessUtil.getUrl(contextBusiness, ""));
    }
    setIsSubmitted(false);
  }, [venueId, formData]);

  const onCancel = () => {
    navigate(BusinessUtil.getUrl(contextBusiness, ""));
  };

  const toggleModule = useCallback(
    (moduleName: string) => {
      let modules: string[] = formData.modules || [];
      if (modules.indexOf(moduleName) > -1) {
        modules = modules.filter((m) => m !== moduleName);
      } else {
        modules.push(moduleName);
      }
      updateForm({ modules });
    },
    [formData]
  );

  useEffect(() => {
    if (venueId && contextBusiness) {
      load();
    }
  }, [venueId, contextBusiness]);

  useEffect(() => {
    getLookupVenues();
  }, [lookupText]);

  useEffect(() => {
    if (currentVenue) {
      updateForm({
        address: currentVenue.address,
        contactName: currentVenue.contactName,
        contactEmail: currentVenue.contactEmail,
        contactPhone: currentVenue.contactPhone,
        modules: currentVenue?.modules ?? [],
        isTransactionFeeEnabled: !!currentVenue?.isTransactionFeeEnabled,
        transactionFee: currentVenue?.transactionFee,
        isKybChecked: currentVenue?.isKybChecked,
      });
    }
  }, [currentVenue]);

  useEffect(() => {
    if (venueId) {
      if (currentVenue) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [currentVenue, venueId]);

  if (isLoading || !contextBusiness) {
    return (
      <Box>
        <LoadingIndicator color="cherryUi.600" />
      </Box>
    );
  }

  return (
    <>
      <PageHeading customStyle={{ height: "116px" }}>
        <Box mt={4}>
          <PageHeading.Title>
            {currentVenue ? currentVenue?.srcVenue?.name : "New Venue"}
          </PageHeading.Title>
        </Box>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <VStack width="100%" spacing="4" alignItems="start">
            {venueId && currentVenue && (
              <Box w="100%">
                <Text fontSize="md" mb={4}>
                  <strong>Venue ID:</strong> {currentVenue.srcVenue.id}
                  <br />
                  <strong>Created On:</strong>{" "}
                  {DateUtil.getLocalDateFormatFromString(
                    currentVenue.createdAt
                  )}
                </Text>
                <Divider />
              </Box>
            )}

            {/* step 1: business lookup */}
            {!venueId && (
              <Box>
                <Text>
                  <strong>Step 1:</strong> Find the venue you wish to enable
                  from the ebet cloud:
                </Text>
                {formData.srcVenue && (
                  <Tooltip label="Edit">
                    <Tag
                      cursor="pointer"
                      onClick={() =>
                        onConfirm("Do you want to edit the venue?", () =>
                          updateForm({ srcVenue: null })
                        )
                      }
                      size="lg"
                      mt={2}
                      bg="#444"
                      color="#fff"
                    >
                      {formData.srcVenue.name} (ID: {formData.srcVenue.id})
                    </Tag>
                  </Tooltip>
                )}
                {!formData.srcVenue && (
                  <Box>
                    <Box mt={2} flex="1">
                      <Box width="100%">{lookupInputElement}</Box>
                    </Box>

                    <Box>
                      {(venueRecords ?? []).map((record: any, i: number) => (
                        <HStack
                          key={`${record.Id}-${i}`}
                          w="100%"
                          padding="2"
                          border="1px solid #efefef"
                          backgroundColor="#f4f4f4"
                          borderRadius="md"
                          as="button"
                          alignItems="start"
                          textAlign="left"
                          onClick={() => onSelectVenueRecord(record)}
                        >
                          <Box w="100%">
                            <Text
                              w="100%"
                              fontSize="md"
                              color="gray.700"
                              fontWeight="600"
                            >
                              {record.name}
                            </Text>
                          </Box>
                          <Text color="gray.600" fontSize="sm">
                            ID:&nbsp;{record.id}
                          </Text>
                        </HStack>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {/* step 2: select address */}
            {(formData.srcVenue || currentVenue) && (
              <Box>
                {!venueId && (
                  <Text>
                    <strong>Step 2:</strong> Select address:
                  </Text>
                )}
                {venueId && <Text>Address:</Text>}

                {!formData.address && (
                  <Box mt={3} w="100%" maxW="400px">
                    <Geosuggest
                      placeholder="E.g. 23, Mulburry St"
                      width="100%"
                      onSuggestSelect={onSuggestSelect}
                    />
                  </Box>
                )}

                {formData.address && (
                  <Tooltip label="Edit">
                    <Box mt={3} onClick={() => updateForm({ address: null })}>
                      {getAddressDisplay(formData.address)}
                    </Box>
                  </Tooltip>
                )}
              </Box>
            )}

            {/* step 2: select address */}
            {(formData.srcVenue || currentVenue) && (
              <Box w="100%" maxW="600px">
                <Text pb={3} fontWeight="bold">
                  Step 3: Contact Details
                </Text>
                <Box px={5} py={3} border="1px dashed #ccc" borderRadius="5px">
                  <FormControl w="100%">
                    <FormLabel>Name</FormLabel>
                    <Input
                      bg="#fff"
                      w="100%"
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        updateForm({ contactName: e.target.value })
                      }
                      value={formData.contactName}
                    />
                  </FormControl>

                  <FormControl w="100%" mt={5}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      bg="#fff"
                      w="100%"
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        updateForm({ contactEmail: e.target.value })
                      }
                      value={formData.contactEmail}
                    />
                  </FormControl>

                  <FormControl w="100%" mt={5}>
                    <FormLabel>Phone</FormLabel>
                    <Input
                      bg="#fff"
                      w="100%"
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        updateForm({ contactPhone: e.target.value })
                      }
                      value={formData.contactPhone}
                    />
                  </FormControl>
                </Box>
              </Box>
            )}

            {/* step 3: modules */}
            {(formData.srcVenue || currentVenue) && (
              <Box>
                {!venueId && (
                  <Text>
                    <strong>Step 3:</strong> Select modules to enable for this
                    venue:
                  </Text>
                )}
                {/* {venueId && <Text>Enabled modules:</Text>} */}
                <Box mt={3}>
                  <Flex>
                    <Switch
                      onChange={() => toggleModule("LinkIT")}
                      isChecked={formData.modules.indexOf("LinkIT") > -1}
                    >
                      Enable cardit+
                    </Switch>

                    {formData.modules.indexOf("LinkIT") > -1 && (
                      <Switch
                        ms={5}
                        isChecked={formData.isKybChecked}
                        onChange={(e) =>
                          updateForm({ isKybChecked: e.target.checked })
                        }
                      >
                        Is KYB done?
                      </Switch>
                    )}
                  </Flex>

                  <Flex mt="10px">
                    <Switch
                      onChange={() => toggleModule("TapIT")}
                      isChecked={formData.modules.indexOf("TapIT") > -1}
                    >
                      Enable tapit
                    </Switch>
                  </Flex>
                </Box>
              </Box>
            )}

            {/* step 2: transaction fee */}
            {(formData.srcVenue || currentVenue) && (
              <Flex alignItems="center" minH="50px">
                <Box flex={1} me={4}>
                  <Switch
                    onChange={(e) =>
                      updateForm({ isTransactionFeeEnabled: e.target.checked })
                    }
                    isChecked={formData.isTransactionFeeEnabled}
                  >
                    {formData.isTransactionFeeEnabled
                      ? "Transaction Fee ($)"
                      : "Transaction Fee"}
                  </Switch>
                </Box>
                {formData.isTransactionFeeEnabled && (
                  <Box maxW="600px">
                    <FormControl w="100%">
                      <Input
                        bg="#fff"
                        w="100%"
                        type="text"
                        placeholder="Enter amount ($)"
                        onChange={(e) =>
                          updateForm({ transactionFee: e.target.value })
                        }
                        value={formData.transactionFee}
                      />
                    </FormControl>
                  </Box>
                )}
              </Flex>
            )}

            {validationError && (
              <Box mt={4}>
                <ErrorMessage>{validationError}</ErrorMessage>
              </Box>
            )}

            {/* generic step loader */}
            {renderLoader()}
            <Box my="20px"></Box>
            <Divider />
            <Box my="20px"></Box>
            {/* {isEditable() && ( */}
            <Box>
              <HStack>
                <Box>
                  <Button
                    colorScheme="cherryButton"
                    type="submit"
                    isLoading={isSubmitted}
                    disabled={isStepLoading || !formData.address}
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                </Box>

                <Box>
                  <Button disabled={isStepLoading} onClick={onCancel}>
                    Cancel
                  </Button>
                </Box>
              </HStack>
            </Box>
            {/* )} */}
          </VStack>
        </Card>
        {error != null && (
          <ErrorMessage>
            An error was encountered while searching businesses.
          </ErrorMessage>
        )}
      </PageContent>
    </>
  );
};
