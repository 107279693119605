import { Text } from "@chakra-ui/react";
import React, { FC } from "react";

const FieldError: FC = ({ children }) => {
  return (
    <Text fontSize="sm" color="red">
      {children}
    </Text>
  );
};

export default FieldError;
