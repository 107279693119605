export const KycHtmlTemplate = `
<!DOCTYPE html>
<html lang="en">

<head>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link
    href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
    rel="stylesheet">

  <style>
    body {
      font-family: "Poppins", sans-serif;
      margin: 0;
      padding: 0;
    }

    #header {
      width: 100%;
      margin-bottom: 0.5cm;
    }

    #header p {
      margin: 0;
      padding: 0;
      margin-bottom: 5px;
      font-size: 10px;
    }

    #content {
      width: 100%;
      border-spacing: 0;
    }

    #content thead td {
      margin: 0;
      border-bottom: 2px solid black;
      padding: 3px;
      font-weight: bold;
      font-size: 11px;
    }

    #content tbody td {
      margin: 0;
      padding: 3px;
      font-size: 10px;
    }

    #footer p {
      margin: 0;
      padding: 0;
      font-size: 10px;
      text-align: left;
    }

    #footer .foot-print {
      margin-top: 0.5cm;
    }

  </style>
</head>

<body>
  <table id="header">
    <tr>
      <td style="width: 100%;">
        <p style="font-weight: bold; font-size: 20px; margin: 0; margin-bottom: 10px;">KYC Report</p>
        <p>
          <span style="font-weight: bold;">Period From:</span>
          &nbsp;&nbsp; {{data.startDate}}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span style="font-weight: bold;">Period To:</span>
          &nbsp;&nbsp; {{data.endDate}}
        </p>
        <p>
          <span style="font-weight: bold;">Business:</span>
          &nbsp;&nbsp; {{data.business}}
        </p>
        <p>
          <span style="font-weight: bold;">Venues:</span>
          &nbsp;&nbsp; {{data.venues}}
        </p>
      </td>
      <td style="vertical-align: baseline; width: auto; text-align: right;">
        <img src="https://firebasestorage.googleapis.com/v0/b/cardit-plus-prod.appspot.com/o/eBet_Logo.svg?alt=media&token=67ac0665-a038-42cf-8cb6-290005fbd4b8" alt="ebet" width="120px"/>
      </td>
    </tr>
  </table>

  <table id="content">
    <thead>
      <tr>
        <td>Member Name</td>
        <td>Venue Name</td>
        <td>Venue Id</td>
        <td>Badge Number</td>
        <td>Date</td>
        <td>Time</td>
        <td>Status</td>
      </tr>
    </thead>
    <tbody>
      {{#each data.items}}
      <tr>
        <td>{{this.memberName}}</td>
        <td>{{this.venueName}}</td>
        <td>{{this.venueId}}</td>
        <td>{{this.badgeNumber}}</td>
        <td>
          {{this.date}}
        </td>
        <td>
          {{this.time}}
        </td>
        <td>{{this.status}}</td>
      </tr>
      {{/each}}
    </tbody>
  </table>

  <hr style="border: 1px solid black; margin-top:0px; margin-bottom: 15px;" />

  <table id="footer">
    <tr>
      <td style="width: auto;">
        <p>
          <span style="font-weight: bold;">Total Successful:</span>
          &nbsp;&nbsp; {{data.totalSuccess}}
        </p>
        <p>
          <span style="font-weight: bold;">Total Consider:</span>
          &nbsp;&nbsp; {{data.totalConsider}}
        </p>
        <p>
          <span style="font-weight: bold;">Total Denied:</span>
          &nbsp;&nbsp; {{data.totalReject}}
        </p>
        <p class="foot-print">
          <span style="font-weight: bold;">Printed:</span>
          &nbsp;&nbsp; {{data.printed}}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span style="font-weight: bold;">User:</span>
          &nbsp;&nbsp; {{data.user}}
        </p>
      </td>
    </tr>
  </table>
</body>

</html>
`;
