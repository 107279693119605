import React, { useState, useRef } from "react";
import { Input, Box, InputGroup, InputRightElement } from "@chakra-ui/react";
import DateUtil from "../../../util/DateUtil";
import { CalendarIcon } from "@chakra-ui/icons";

type DatePickerProps = {
  onChange: (value: string) => void;
  dateFormat?: string;
  max?: string;
  min?: string;
};

const DatePicker = ({
  onChange,
  dateFormat = "dd/mm/yyyy",
  max,
  min,
}: DatePickerProps) => {
  const [value, setValue] = useState({
    date: "",
    text: "",
  });
  const dateInputRef = useRef<any>(null);
  const textInputRef = useRef<any>(null);

  const handleTextInputFocus = () => {
    if (dateInputRef.current) {
      dateInputRef.current?.showPicker();
    }
  };

  const handleDateChange = (e: any) => {
    const selectedDate = e.target.value || "";
    if (textInputRef.current) {
      textInputRef.current?.blur();
    }
    try {
      setValue({
        date: selectedDate,
        text: DateUtil.getFormattedDate(new Date(selectedDate), "dd/MM/yyyy"),
      });
    } catch (_e) {
      setValue({
        date: "",
        text: "",
      });
    }
    onChange(selectedDate);
  };

  return (
    <Box position="relative">
      <InputGroup>
        <Input
          type="text"
          ref={textInputRef}
          value={value.text}
          onClick={handleTextInputFocus}
          placeholder={dateFormat.toLowerCase()}
          fontSize={11}
          backgroundColor="white"
          borderRadius={0}
          size="sm"
          cursor="pointer"
          readOnly
        />
        <InputRightElement>
          <CalendarIcon
            color="grey"
            position="absolute"
            top="2"
            onClick={handleTextInputFocus}
          />
        </InputRightElement>
      </InputGroup>
      <Input
        type="date"
        ref={dateInputRef}
        value={value.date}
        onChange={handleDateChange}
        min={min}
        max={max}
        position="absolute"
        top="8"
        left="0"
        width="0"
        height="0"
        opacity="1"
      />
    </Box>
  );
};

export default DatePicker;
