import { Box } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import HomeIcon from "../../../assets/Home_Business.svg";
import MembersIcon from "../../../assets/Members.svg";
import ReportIcon from "../../../assets/Reports.svg";
import SessionIcon from "../../../assets/Sessions.svg";
import SettingIcon from "../../../assets/Settings.svg";
import UserIcon from "../../../assets/Users.svg";
import { useBusiness } from "../../context/BusinessContext";
import { useProfile } from "../../context/ProfileContext";
import {
  DesktopMenuItemV2,
  DesktopSubMenuItemV2,
  MobileMenuItemV2,
  MobileSubMenuItemV2,
} from "./HomeMenuV2";

export const AdminMenuV2 = ({ isDesktop }: { isDesktop: boolean }) => {
  const MenuItem = isDesktop ? DesktopMenuItemV2 : MobileMenuItemV2;
  const SubMenuItem = isDesktop ? DesktopSubMenuItemV2 : MobileSubMenuItemV2;
  const contextBusiness = useBusiness();
  const profileContext = useProfile();
  const location = useLocation();
  const path = location.pathname;
  const isBusiness =
    path.split("/").length === 4 && path.includes("admin/businesses");

  return (
    <Box
      w="100%"
      pt={50}
      bg="#181419"
      maxH="calc(100vh - 70px)"
      overflowY="auto"
    >
      <MenuItem
        target={`/admin/businesses/${contextBusiness?._id}`}
        icon={HomeIcon}
        title="HOME"
        isActive={isBusiness}
      />
      <MenuItem
        target={`/admin/businesses/${contextBusiness?._id}/members`}
        icon={MembersIcon}
        title="MEMBERS"
        isActive={path.includes("members")}
      />
      <MenuItem
        target={`/admin/businesses/${contextBusiness?._id}/users`}
        icon={UserIcon}
        title="USERS"
        isActive={path.includes("users")}
      />

      <MenuItem
        target={`/admin/businesses/${contextBusiness?._id}/reports`}
        icon={ReportIcon}
        title="REPORTS"
        isActive={path.includes("/reports")}
      />
      <MenuItem
        target={`/admin/businesses/${contextBusiness?._id}/settings`}
        icon={SettingIcon}
        title="SETTINGS"
        isActive={path.includes("/setting")}
      />
      {profileContext.isWorldAdmin && (
        <MenuItem
          target={`/admin/businesses/${contextBusiness?._id}/sessions`}
          icon={SessionIcon}
          title="SESSIONS"
          isActive={path.includes("/session")}
        />
      )}
    </Box>
  );
};
