import React from "react";
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/input";
import { Spinner } from "@chakra-ui/spinner";
import { Box, InputLeftElement, Skeleton } from "@chakra-ui/react";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";

export interface SearchInputProps {
  isDebouncing?: boolean;
  isLoading?: boolean;
  inputref?: React.MutableRefObject<HTMLInputElement>;
  setQuery?: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchInput = (props: InputProps & SearchInputProps) => {
  const { isDebouncing: isLoading, setQuery, inputref, ...inputProps } = props;
  return (
    <>
      {(props.isLoading && (
        <Box width="100%" boxShadow="lg" padding="2">
          <Skeleton height="30px" width="100%" />
        </Box>
      )) || (
        <InputGroup backgroundColor="#fff" shadow="sm">
          <InputLeftElement children={<SearchIcon />} />
          <Input {...inputProps} ref={inputref} />
          {(isLoading && <InputRightElement children={<Spinner />} />) ||
            (props.value && setQuery && (
              <InputRightElement
                children={
                  <CloseIcon cursor="pointer" onClick={() => setQuery!("")} />
                }
              />
            ))}
        </InputGroup>
      )}
    </>
  );
};
