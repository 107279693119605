import { theme } from "@chakra-ui/theme";
export const Button = {
  variants: {
    solid: (props: any) => ({
      ...theme.components.Button.variants.solid(props),
      borderRadius: "full",
      colorScheme: "cherryButton",
      fontWeight: 400,
    }),
  },
};
