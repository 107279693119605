import React, { useEffect, useRef } from "react";

/**
 * Creates a ref that will be set to true when the component is unmounted. Useful for ignoring callbacks
 * when no longer needed.
 */
export const useDidUnmount = (): React.MutableRefObject<boolean> => {
  const didUnmount = useRef<boolean>(false);
  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  return didUnmount;
};
