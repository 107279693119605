import React from "react";
import { CloseIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  HStack,
  VStack,
} from "@chakra-ui/react";

type ModalErrorProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subTitle?: string;
  errors?: string[];
};

const ModalError = ({
  isOpen,
  onClose,
  title,
  subTitle,
  errors,
}: ModalErrorProps) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="lg">{title}</Text>
        </ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <ModalBody>
          {subTitle && <Text fontSize="md">{subTitle}</Text>}
          <VStack spacing={1} mt={3} mb={4} alignItems="start">
            {errors?.map((error, index) => (
              <HStack key={`${index}-${error}`} spacing={2}>
                <CloseIcon boxSize={2} color="red" />
                <Text fontSize="sm">{error}</Text>
              </HStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalError;
