import React, { useCallback, useState } from "react";
import { User } from "firebase/auth";
import { useFirebase } from "../../context/FirebaseContext";
import { Box, VStack, Text } from "@chakra-ui/layout";
import { Heading, Button, Image } from "@chakra-ui/react";

import ebetLogo from "assets/ebet-logo.png";
import loginBg from "assets/login-bkg.svg";
import { LoginForm } from "../../forms/LoginForm";
import { useToast } from "../../hooks/useToast";
import { PasswordResetForm } from "../../forms/PasswordResetForm";

export const PrivacyPolicy = () => {
  return (
    <>
      <Box position="relative">
        <Box m="0 auto" pt="50px" maxW="1024px">
          <Heading>Privacy at ebet</Heading>
          <br />
          This Privacy Policy applies to ebet Gaming Systems Pty Limited ABN 50
          086 218 831and its subsidiaries (ebet, we, our and us) that are
          subject to the Privacy Act 1988 (Cth) (Privacy Act). At ebet we
          understand the importance you attach to your personal information.
          This Privacy Policy details how we handle personal information as we
          are committed to complying with the Australian Privacy Principles
          (APPs), which are contained in the Privacy Act. ebet has implemented
          practices, procedures and systems that promote compliance with the
          APPs and allow us to deal with your enquiries and complaints
          concerning compliance with APPs and any APP Code applying to us. You
          may, at any time, contact us to discuss any privacy-related queries at
          privacy@ebetsystems.com.au.
          <Heading size="md" my="20px">
            Application
          </Heading>
          This Privacy Policy applies to personal information. Under the Privacy
          Act, “personal information” is information or an opinion about an
          identified individual or an individual who is reasonably identifiable.
          Common examples include a customer’s or job applicant’s name, address,
          telephone number or date of birth.
          <Heading size="md" my="20px">
            Your agreement
          </Heading>
          Please read this Privacy Policy carefully to understand our practices
          regarding your personal information and how we will treat it. By using
          any ebet website or product/app, agreeing to the terms and conditions
          for any ebet product or training account, providing services to ebet,
          or otherwise providing your personal information to us, you confirm
          that you have read and agree to this Privacy Policy. We will review
          this Privacy Policy periodically and may modify and update it at any
          time. Changes to this Privacy Policy will come into effect immediately
          upon such changes being uploaded on our websites or product/app.
          However, where changes are material, we will provide you with
          reasonable notice of the changes before the changes take effect. If
          applicable, your continued use of our products and services following
          the effective date of the amendments will constitute your acceptance
          of these changes.
          <Heading size="md" my="20px">
            1. Information we collect and hold
          </Heading>
          The kind of information we collect, and hold depends on your dealings
          with us and on the requirements of the laws, regulations, licence
          conditions and betting rules applying to us. However, it should be
          noted that the responsibility for conducting Know Your Customer (KYC)
          checks does not rest with ebet.
          <br />
          <br />
          This section provides examples of the kinds of information we may
          collect and hold about you. The lists below are not exhaustive.
          <br />
          <br />
          <Heading size="md" my="20px">1.1 Making website enquiries</Heading>
          <br />
          If you send us an enquiry through one of our websites, we may collect
          your name, phone number and email address.
          <br />
          <br />
          <Heading size="md" my="20px">1.2 Working for ebet</Heading>
          <br />
          If you wish to be, or are, or have been, contracted, employed, or
          otherwise engaged by ebet as part of its workforce (directly or
          indirectly, e.g. as an employee of a labour supply company), we may
          collect and hold personal information about you, which may include:
          <br />
          <br />
          • name;
          <br />
          • residential and email addresses;
          <br />
          • telephone number;
          <br />
          • academic results and qualifications;
          <br />
          • expertise and experience;
          <br />
          • employment history;
          <br />
          • personal interests;
          <br />
          • current salary and salary expectations;
          <br />
          • your next-of-kin details; and
          <br />
          • background information about you including, for example, your
          criminal record (where you have consented to us collecting this
          information) and whether you’re a politically exposed or sanctioned
          person. We may also collect your superannuation fund and Tax File
          Number details, if required by law, for example, under the
          Superannuation Guarantee (Administration) Act, the Superannuation
          Industry (Supervision) Act, the Income Tax Assessment Acts and
          Taxation Administration Act.
          <br />
          <br />
          ebet may use and disclose such information in accordance with this
          Privacy Policy.
          <br />
          <br />
          In addition, ebet may search government records and publicly available
          sources, including social media and other internet sources, for
          background information about you. ebet requires background information
          as part of its assurance program on the identity, honesty, and
          integrity of prospective and current employees. Background checks may
          require ebet to disclose information about you to certain regulators
          and other third parties.
        </Box>

        <Box m="0 auto" pt="50px" maxW="1024px">
        <Heading size="md" my="20px">1.3 Supplying goods or services to ebet</Heading>


If you’re a supplier of goods or services to ebet, or are employed or engaged by such a
supplier, we may collect personal information about you, including:<br/>
• name;<br/>
• position;<br/>
• business and email addresses;<br/>
• telephone number; and<br/>
• other relevant background information, which, for the specific products or
services you are supplying to ebet, may include your criminal record and your
status as a politically exposed or sanctioned person.<br/><br/>
ebet may, for example, search government records and publicly available sources,
including social media and other internet sources, for background information about
you. ebet requires background information as part of its supplier due diligence activities.<br/><br/>
Background checks may require ebet to disclose information about you to certain
regulators and other third parties.<br/><br/>
<Heading size="md" my="20px">1.4 Use of an ebet application</Heading>
In the course of your use of an ebet application (e.g. website, product, app)
(collectively the Applications), we may collect information about you as part of a
transaction or function provided by that Application. We do not forward this information
onto third parties unless agreed to in accordance with the terms of that Application.
We will not be collecting any credit card information nor storing any credit card
information. Where the Applications include a payment function, we will not be
collecting any credit card information nor storing it. At time of payment, all credit card
details are collected by external payment providers, and their privacy statements can
be found at their websites. These external providers currently include:<br/>
• Paypal (PayPal Privacy Statement)<br/>
• Secure Pay (SecurePay Sign Up Privacy Notice - Australia Post (auspost.com.au))<br/>
• Payment Express (Windcave | Privacy Policy Page).<br/><br/>
When you visit our Application, it's important to understand that your Internet Service
Provider (ISP) may automatically collect certain types of information. This could include:<br/>
• Your internet address;<br/>
• Your domain name;<br/>
• The date and time of your visit to the website.<br/>
Please note that this information is part of the ISP's operational data and is governed by
the privacy policies of your ISP, not ebet.<br/><br/>
Data Collection by ebet’s Applications:<br/><br/>
In contrast, our Applications may collect different types of data to enhance your user
experience and for website development purposes. This includes:<br/>
• The length of your session in our Application;<br/>
• The pages you have accessed within the Application;<br/>
• The website URL that referred you to our Application;<br/>
• The type and version of the browser you are using;<br/>
• The operating system of your computer.<br/>

This data is used solely for statistical analysis and website development and typically
does not personally identify you.<br/><br/>
Use of Cookies:<br/>
• Our Applications use cookies to track how you use our Application and to tailor
your user experience.<br/>
• You have the option to delete cookies from your computer or device at any
time. However, please be aware that this will remove all cookies, not just those
associated with our Applications.<br/><br/>
<Heading size="md" my="20px">1.5 Acquiring goods or services from ebet</Heading>
If you acquire goods or services from ebet, or are employed or engaged by an entity
which does, we may collect personal information about you, including:<br/>
• name;<br/>
• position;<br/>
• business and email addresses;<br/>
• telephone number; and<br/>
• background information about you, including, for example, your credit history
and criminal record (if you have consented to us doing so) and whether you’re a
politically exposed or sanctioned person.<br/><br/>
ebet may, for example, search government records, credit listing agencies and publicly
available sources, including social media and other internet sources, for background
information about you. ebet requires background information as part of its customer due
diligence activities. Background checks may require ebet to disclose information about
you to certain regulators and other third parties.<br/><br/>
<Heading size="md" my="20px">1.6 Participating in ebet training programs</Heading>
If you register or enrol to participate in an ebet training program, we will collect personal
information including your:<br/>
• name;<br/>
• residential and email addresses;<br/>
• date of birth; and<br/>
• telephone number<br/><br/>
We may also ask for other details from you, such as:<br/>
• information about qualifications and other training you’re undertaking or have
completed;<br/>
• sensitive information, including citizenship, Aboriginal or Torres Strait Islander origin
and any disability you may have (for the purpose of required reporting to
relevant Commonwealth, state and territory government authorities and
agencies);<br/><br/>

<Heading size="md" my="20px">1.7 Information from other ebet companies</Heading>
We may collect your information, for the purposes outlined within this document, from
other companies in the ebet Group (our related bodies corporate) such as:<br/>
• your name;<br/>
• your contact details;<br/>
• your date of birth;<br/>
• copies of identification documents;<br/>
• lifestyle or betting frequency information; and<br/>
• recordings of telephone calls you have made to our call centre.<br/><br/>

<Heading size="md" my="20px">1.8 Additional information we may collect for the AML/CTF Act</Heading>
Under the Anti-Money Laundering and Counter-Terrorism Financing Act 2006 (Cth), we
may have to:<br/>
• request further personal information from you (e.g. confirmation of your account
transactions, your occupation and/or your employer);<br/>
• collect information about your transactions on any accounts, including any email
you send to us, and report activity externally to AUSTRAC; and/or<br/>
• collect other information about you that we reasonably require to help assess
your risk to our business under the AML/CTF Act, as defined in clause 9.<br/><br/>
<Heading size="md" my="20px">1.9 Video surveillance</Heading>
For security reasons, we may collect video surveillance footage at certain ebet premises
for reasons including monitoring of gaming, prevention of theft and other
illegal/undesirable activity, and coordination of emergency and alarm responses. This
surveillance is carried out using visual surveillance devices on a continuous and ongoing
basis by our surveillance/security/compliance teams (or third parties).<br/><br/>
<Heading size="md" my="20px">1.10 Information about your online behaviour</Heading>
We may collect information regarding your online behaviour, including:<br/>
• your responses to correspondence, promotions, giveaways and competitions;<br/>
• the time, date and URL of each request for a page from our web server and
other web servers assisting us (including the times that you log in to any account
to help ensure the security of your account and to help verify that the person
operating the account is you), plus information on the browser software being
used to make the request;<br/>
• “click stream” information from your use of our websites (such as information
about areas of our websites you’ve accessed and the time and date of access);<br/>
• doubleclick ad serving information from your use of our websites (such as
information about whether a marketing campaign has been effective);<br/>
• information about your preferences relating to the use of our websites; and<br/>
• information from your computer or device allowing us to: analyse trends,
administer our websites, track your web navigation, and gather broad
demographic information for aggregated use.<br/><br/>

To do this, we may use cookies (pieces of data sent by a website to your computer or
device and stored on the hard drive). Cookies allow a website to identify and interact
with your computer or device. The information stored in cookies may be used to offer our
products and services directly to you and customise and improve our websites. We don’t
use cookies to retrieve information that was not originally sent by us to you in a cookie.<br/><br/>
You can set your browser to accept or reject all cookies, or notify you when a cookie is
sent. If you reject cookies or delete our cookies, you may still use our websites, but may
not have access to “account holder only” or other restricted areas.<br/><br/>
<Heading size="md" my="20px">2. How we collect personal information</Heading>
We generally collect your personal information directly from you via one of our websites,
apps or via telephone, by mail or in person. However, we may collect information from a
third party if it isn’t reasonable or practical to collect it from you or if necessary to satisfy
our legal obligations.<br/><br/>
For example, we may collect your personal information from:<br/>
• a referee nominated by you when you apply for a job with us;<br/>
• recruiters we have engaged to help us fill roles at ebet;<br/>
• your employer if you’re employed by a company that provides goods or services
to us;<br/>
• your employer if you are employed by one of our customers, venues or outlets;<br/>
• our related bodies corporate;<br/>
• any entity that provides training consultancy services to us;<br/>
• public sources (e.g. social media sites, Google and other websites), e.g. for the
purposes of our AML/CTF Act compliance program;<br/>
• BetStop, the National Self Exclusion Register, to verify if you have chosen to self-
exclude from gambling activities;<br/>
• if relevant, any state regulatory bodies to confirm whether you’re a voluntarily self
excluded patron in Australia or any other country; and<br/>
• any relevant Commonwealth, state or territory government authority or agency.
All phone calls to ebet Customer Support are recorded for regulatory, verification and
quality purposes.<br/><br/>
<Heading size="md" my="20px">3. Why we collect and hold personal information</Heading>
<Heading size="md" my="20px">3.1 Reasons for collecting, using and holding your personal information</Heading>
Our reasons include:<br/>
• to identify you and verify your identity;<br/>
• to manage, administer, and control the quality of the products and services we
provide;<br/>
• to market our products and services;<br/>
• to market the products and services of our related bodies corporate;<br/>
• to enable our related bodies corporate and selected third party partners to
market products and services;<br/>
• to operate self-exclusion, pre-commitment limit and bet exemption programs
and comply with our other responsible gambling commitments;<br/>
• to consider you for employment, if you apply for a position with us;<br/>
• to do business with our suppliers of goods and services (you may be a supplier, or
employed or engaged by a supplier);<br/>
• to take reasonable steps to prevent a contravention of workplace laws by your
employer if you work in our retail network of agencies, venues and outlets;<br/>
• to notify you if you have won a competition, promotion or prize;<br/>
• to carry out research and analysis related to our products and services;<br/>
• to develop and improve our products and services;<br/>
• to identify your behaviour, habits and preferences for the purposes of
personalizing our services, enhancing user experience, and providing targeted
marketing and promotions tailored to your interests;<br/>
• to assist you with enquiries or complaints;
• to assess whether you pose a risk to our business or the businesses of our related
bodies corporate;<br/>
• to protect our rights and property and those of any ebet customer;<br/>
• to maintain a register of individuals who have undertaken specific training
programs provided by us; and<br/>
• any other purpose required or permitted by law.<br/><br/>

Another reason for collecting, using and holding your personal information is to comply
with our legal obligations. For example, we may be legally required to collect personal
information under:<br/>
• the AML/CTF Act (and we may collect your personal information for the purpose
of implementing our AML/CTF Act compliance program);<br/>
• the Corporations Act 2001 (Cth);<br/>
• the conditions of licences applying to us; and<br/>
• legislation and contractual obligations with authorised sports controlling bodies
(domestic and international).<br/><br/>

If you are a job applicant, employee or contractor with ebet, we will collect, use and
hold your personal information for purposes relating to your engagement, training,
performance management, payroll, superannuation, health and safety, for
administration and staff management purposes.<br/><br/>

<Heading size="md" my="20px">3.2 What happens if we don’t collect your personal information?</Heading>
Unfortunately, if we’re unable to collect your personal information, we may not be able
to provide our products and services to you and/or any account that you may hold may
not perform properly. For example:<br/>
• if you don’t provide the necessary identification documents when required, your
account may be frozen and you will not be able to access your funds until we
have verified your identity;<br/>
• if you refuse or are unable to provide further information we’re required to collect
under the AML/CTF Act, we may freeze your account until you provide the
additional information;<br/><br/>

if you don’t provide sufficient information about your identity, qualifications, and
experience, we may not be able to consider you for employment or engagement with
us.<br/><br/>

<Heading size="md" my="20px">4. How we disclose personal information</Heading>

Information about how we disclose personal information is below. This list is not intended
to be exhaustive and there may be other third parties to which we give your personal
information (for example, professional advisors or insurers) where required or permitted
by law.<br/><br/>

We may also be required to disclose personal information for the purpose of
investigations by law enforcement agencies like the Australian Federal Police, the
Australian Competition and Consumer Commission, the Australian Securities and
Investments Commission and the Australian Crime Commission.<br/><br/>

We will not give, sell, trade or otherwise disclose any personal information about you to a
third party unless:<br/>
• it is in the course of the provision of the Solution to you;<br/>
• you have provided us with your consent;<br/>
• we are required to do so by law; or<br/>
• in the case of a where you are a member of one of our customers, in accordance
with the terms and conditions of your membership with the relevant club, venue or
association.<br/><br/>
<Heading size="md" my="20px">4.1 Our employees and contractors</Heading>
We may disclose personal information to our employees and contractors, but only as
needed to perform their jobs or provide their services. (Please note that they have
obligations to treat the personal information they access as confidential.)<br/><br/>

<Heading size="md" my="20px">4.2 Public relations</Heading>
If you win a major prize, we may give your details to external publicity businesses for
reasonable promotional activities. We will ask for your consent before we do this, and
you can choose not to have your name disclosed in this way.<br/><br/>
<Heading size="md" my="20px">4.3 Service providers</Heading>
We may disclose personal information to organisations that assist us to:<br/>
• check your name against government or other watch lists (e.g. if you’re the
subject of economic sanctions imposed by the government);<br/>
• provide, manage and administer our products and services, our business and our
business systems (including mailing houses, our share registry provider, printers,
public relations and advertising agencies, postal services, call centres, product
development consultants, providers of customer relationship management
(CRM) and affiliate management services, auditors, lawyers, providers of fraud
detection services, IT technicians and software providers, IT consultants, website
developers, providers of services to support our training programs, providers of
payment processing services, data centre providers and providers of technology
platforms);<br/>
• develop and market our products and services (including market research
analysts);<br/>
• assess risks under the AML/CTF Act;<br/>
• manage ebet loyalty programs (including updating points balances, identifying
any free, bonus or promotional points owing to members, and sending members
information);<br/>
• comply with industry standards (such as the Payment Card Industry Data Security
Standard), and securely manage processing of your credit card payments and
storage of your credit card details;<br/>
• analyse trends and aggregated demographic information, and target your
interests; and<br/>
• inspect, cleanse, transform and model such personal information for the purpose
of discovering useful information, informing conclusions, and supporting decision
making within ebet, whether or not you are a current supplier, customer,
employee or contractor.<br/><br/>
<Heading size="md" my="20px">4.4 Regulators/industry bodies</Heading>
We may disclose your personal information to:<br/>
• regulators and law enforcement agencies (including those responsible for
enforcing the AML/CTF Act, such as AUSTRAC);<br/>
• sports integrity bodies or Sport Controlling Bodies;<br/>
• respond to an enquiry from a government agency under state, territory or
Commonwealth laws;<br/>
• any other relevant Commonwealth, State and Territory government authorities
and agencies.<br/><br/>

<Heading size="md" my="20px">4.5 Related corporations</Heading>

We may disclose personal information to our related bodies corporate:<br/>
• so they can comply with their obligations under laws, regulations, licence
conditions and betting rules;<br/>
• for legitimate employment-related purposes (as determined reasonably by us
such as for technician’s licensing;<br/>
• so they can send you marketing information directly; and<br/>
• as otherwise required or permitted by law.<br/><br/>

<Heading size="md" my="20px">4.6 Loyalty partners</Heading>

If you join an ebet loyalty program, with your consent we may disclose your personal
information to business partners outside ebet and from time to time other third parties, so
they can send you promotional offers and information about their products and services.<br/><br/>

<Heading size="md" my="20px">4.7 Using credit reporting bodies to verify your identity</Heading>

By agreeing to our Privacy Policy, you are giving explicit consent for us to share your
personal information – specifically, your name, residential address, and date of birth –
with credit reporting agencies. This is a necessary step for compliance with the Anti-
Money Laundering and Counter-Terrorism Financing Act (AML/CTF Act) and is
conducted solely for the purpose of verifying your identity. We ensure that this consent is
clearly sought either at the time of your acceptance of our Privacy Policy or via a
separate, distinct consent form, to guarantee you are fully informed and consent to this
particular use of your personal data.<br/><br/>

The identity verification process involves credit reporting agencies using the details we
provide to match against their own credit information records. This matching process is
done to create an assessment of whether your details correspond, aiding in the
verification of your identity. If your identity cannot be verified through this assessment, we
will promptly inform you.<br/><br/>

We understand that some users may prefer alternative methods for identity verification. If
you opt not to have your identity verified through a credit reporting agency, you can
choose to opt out. This can be done by getting in touch with the ebet customer support
team at 1300 060 026 or your specific ebet business contact. We will then provide you
with different methods of identity verification and clear instructions on how to opt out, as
well as the implications of doing so. This approach ensures we respect and comply with
your rights and preferences, in accordance with the Privacy Act.<br/><br/>

<Heading size="md" my="20px">4.8 Marketing</Heading>

We may use and disclose your personal information for marketing purposes, including to:<br/>
• identify your behaviours, habits and preferences for the purpose of personalising
our services, enhancing user experience, and providing targeted marketing and
promotions tailored to your interests;<br/>
• let you know about upcoming events, training programs, promotions, offers and
new products/services or other opportunities, via direct mail, email, SMS, MMS
and/or phone calls (or any other means of marketing communication);<br/>
• communicate with you as a member of a loyalty program to:<br/>
o inform you of marketing and promotional offers; and<br/>
o notify you of points earned (including free, bonus and promotional
points),
via email, SMS, MMS, phone calls, direct mail or other means of
communication from us and/or our loyalty partners;<br/>
• send you product and service information on behalf of third parties; and<br/>
• enable our related bodies corporate and selected third party partners to provide
you product and service information directly.<br/><br/>

When signing up for an account with us, we ask for your consent before sending you
marketing communications. Each time you’re sent a marketing communication, you’ll
be able to opt-out from future marketing communications by following the opt-out
instructions provided in the communication. You can also opt-out from future marketing
communications at any time (email: privacy@ebetsystems.com.au). We will promptly
action any opt-out request.<br/><br/>

If you consent to receiving marketing communications from ebet, you also consent to
receiving marketing material from our third-party partners, but you may withdraw that
consent at any time. To withdraw your consent, follow the instructions in the advertising
material or on the third party’s website or email: privacy@ebetsystems.com.au<br/><br/>

<Heading size="md" my="20px">4.9 Replacement providers</Heading>

If we:<br/>
• transfer responsibility for providing an ebet product or service to another business;
or<br/>
• stop providing an ebet product or service (or limit it), and another business
continues to offer a similar product/service,
we may disclose personal information to the other business so the product or service can
continue to be provided to you or the other business can offer you a similar product or
service.<br/><br/>

By agreeing to the terms and conditions for your ebet account(s) (product or training
account(s)), you consent to this disclosure: however, you may withdraw that consent at
any time - email: privacy@ebetsystems.com.au<br/><br/>

<Heading size="md" my="20px">4.10 Monitoring phone calls</Heading>

We record various types of telephone calls. You will be informed if a call is being
recorded at the commencement of the call. We may disclose the details of your calls
with us to enforcement agencies, regulators, and external advisors. Where we
reasonably believe that the disclosure of your personal information is reasonably
necessary for an endorsement-related activity conducted by (or on behalf of) an
enforcement body, we will keep a record of the disclosure.
We won’t notify you of those disclosures. If you’d like to request a copy of your recorded
telephone calls, please make a request following the instructions in the “Access” section
below.<br/><br/>

<Heading size="md" my="20px">4.11 Audit, market research and training</Heading>

We may use and disclose your personal information for audit, market research and
internal training purposes.<br/><br/>

<Heading size="md" my="20px">4.12 Protection of people and our rights and property</Heading>
We may use and disclose your personal information where we reasonably believe it’s
necessary to protect any person or our rights or property.<br/><br/>

<Heading size="md" my="20px">5. Security of personal information</Heading>
ebet takes reasonable precautions to protect the personal information it holds from:<br/>
• misuse, interference, and loss; and<br/>
• unauthorised access, modification, or disclosure.<br/><br/>

These precautions may include, for example, the protection of passwords using industry
standard encryption; measures to preserve system security and restrict unauthorised
access; and back-up systems to reduce the risk of accidental or malicious loss of data.<br/><br/>
We may use third party providers to store personal information electronically: we take
reasonable steps to ensure this information is held securely.
Unfortunately, there’s always risk involved in sending information through any channel
over the internet. You send information over the internet entirely at your own risk.<br/><br/>

<Heading size="md" my="20px">6. Access or correct my personal information?</Heading>

We will take reasonable steps to ensure the personal information we collect about you or
that we use or disclose is accurate, up-to-date and complete. Please let us know if
there’s a change to any of the details you have given to us.<br/><br/>

<Heading size="md" my="20px">6.1 Access</Heading>

You have a right to ask for access to the personal information we hold about you. Please
contact us at:<br/>
Privacy Officer, ebet, Level 9, 150 George Street, Parramatta, NSW 2150.<br/>
Telephone: 1300 060 026<br/>
or email: privacy@ebetsystems.com.au<br/><br/>

We will usually respond to your access request within 30 days of your request.<br/><br/>

You will not be charged for making an access request, but you may be charged for the
reasonable time and expenses incurred in compiling information in response to your
access request. If there is a fee for accessing your personal information, we’ll confirm the
amount before providing the information.<br/><br/>

In some circumstances we may decline to grant you access to your personal information
in accordance with the APPs, for example, if the release of your personal information
would have an unreasonable impact on the privacy of others. If we refuse your request,
we will provide you with a written notice, which set out the reason(s) for our decision and
the mechanisms available to complain about our refusal.<br/><br/>

We may require evidence of your identity as part of the process of providing you with
access to your personal information.<br/><br/>

<Heading size="md" my="20px">6.2 Corrections</Heading>

You have a right to ask us to correct the personal information we hold about you. Please
contact us at:<br/>
Privacy Officer, ebet, Level 9, 150 George Street, Parramatta, NSW 2150.<br/>
Telephone: 1300 060 026<br/>
or email: privacy@ebetsystems.com.au<br/><br/>

It’s not always possible to remove or modify information in our databases, but we’ll take
reasonable steps to correct your personal information to ensure that it’s accurate, up-to-
date, complete, relevant, and not misleading.<br/><br/>

If we decline your correction request, we will provide you with a written notice, which
sets out the reason(s) for our decision and the mechanisms available to complain about
our refusal.<br/><br/>
If we decline to make your requested correction, you may request us to attach to your
personal information a statement that it is inaccurate, out-of-date, incomplete, irrelevant
or misleading. We will take reasonable steps to do this.<br/><br/>
If we disclosed your personal information to a third party before correcting it, you may
request us to notify the third party of the correction we’ve made. We will take
reasonable steps to do so unless this would be impractical or unlawful.<br/><br/>
We won’t charge you to request a correction, for making a correction or for attaching a
statement to a record.<br/><br/>

<Heading size="md" my="20px">7. Making complaints</Heading>

If you have a concern about how we’ve handled your personal information, please let
us know so we can address the problem. You can contact us at:<br/>
Privacy Office, ebet, Level 9, 150 George Street, Parramatta, NSW 2150.<br/>
Telephone: 1300 060 026<br/>
or email: privacy@ebetsystems.com.au<br/><br/>

To lodge a formal complaint, please contact ebet in writing. We’ll attempt to respond
within a reasonable time, usually 30 days. If the matter is more complex or our
investigation may take longer, we will let you know.<br/><br/>

ebet treats all privacy complaints seriously and any complaint will be assessed with the
aim of resolving the issue in a timely and efficient manner. We request that you
cooperate with us during this process and provide us with any relevant information that
we may need to investigate the complaint.<br/><br/>

If your complaint is not resolved by us, you can refer it to the Office of the Australian
Information Commissioner (OAIC). The OAIC’s contact details are:<br/>
Office of the Australian Information Commissioner<br/>
GPO Box 2999 Canberra ACT 2601 Australia<br/>
Phone: 1300 363 992 Fax: 02 9284 9666<br/>
Website: www.oaic.gov.au<br/><br/>

<Heading size="md" my="20px">8. Disclosure of personal information overseas</Heading>

We may disclose your personal information to recipients located outside Australia that
provide services to us. For example, we may disclose your personal information to third
parties in the United States of America, United Kingdom, Japan, Ireland and India, who
provide customer relationship management services, identification and fraud detection
services, cloud and data storage services, IT support services and other third-party
services to us.<br/><br/>

<Heading size="md" my="20px">9. Sensitive information</Heading>

We won’t collect sensitive information, such as your:<br/>
o racial or ethnic origins;<br/>
o political opinions;<br/>
o religious or philosophical beliefs;<br/>
o sexual orientation or practices;<br/>
o criminal record; or<br/>
o health information<br/><br/>
unless it is:<br/>
• given with your consent and it is reasonably necessary for one of our functions or
activities;<br/>
• in relation to you working with (or applying to work with) ebet, e.g. health
information relating to a disability that needs to be accommodated in the
workplace;<br/>
• to provide certain training programs or services effectively to you; or
• required or authorised by law.<br/><br/>

By agreeing to this Privacy Policy, you consent to us collecting information about you
where required under the AML/CTF Act (for example, to verify your identity), and we
may collect this information from third parties. The information will be stored in an
AML/CTF risk register that we take reasonable steps to keep secure, and you may not
have access to it.<br/><br/>

If we consider that you pose a high risk to our business, we may disclose information
about you to government authorities and may use the personal information as grounds
to stop providing our services to you. We may freeze your account without warning and
without telling you we are relying on personal information to do so.<br/><br/>

<Heading size="md" my="20px">10. How long can ebet keep personal information?</Heading>

If ebet:<br/>
• no longer needs to use or disclose your personal information for any purpose
that’s authorised under the APPs, this Privacy Policy, or otherwise in writing; and<br/>
• is not legally required to retain that information,
then it will take reasonable step<br/>
        </Box>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </Box>
    </>
  );
};
