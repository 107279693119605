import { Box, Divider, HStack } from "@chakra-ui/layout";
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../context/ProfileContext";
import { useUserContext } from "../../context/UserContext";

export const CurrentUserV2 = () => {
  const { user, logout } = useUserContext();
  const profile = useProfile();
  const navigate = useNavigate();

  const { firstName, lastName, email } = profile;

  const getInitialName = () =>
    useMemo(() => {
      const name = [firstName, lastName].filter(Boolean).join(" ");
      const nameArray = name ? name.split(" ") : email?.split("@");
      if (nameArray.length > 1) {
        return {
          initial: `${nameArray[0].slice(0, 1)}${nameArray[1].slice(
            0,
            1
          )}`.toUpperCase(),
          fullName: name || email?.split("@")[0],
        };
      } else {
        return {
          initial: nameArray[0].slice(0, 2).toUpperCase(),
          fullName: name || email?.split("@")[0],
        };
      }
    }, [profile]);

  if (!user) {
    return null;
  }

  const onLogout = () => {
    navigate("/", { replace: true });
    logout();
  };

  return (
    <HStack>
      <Box pr={3}>
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <Box
              role="button"
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="50px"
              h="50px"
              bg="#CE445C"
              borderRadius="100%"
              color="white"
              marginRight="14px"
              fontSize="18px"
              fontWeight={500}
            >
              {getInitialName().initial}
            </Box>
          </PopoverTrigger>
          <PopoverContent _focus={{ outline: "none" }}>
            <PopoverBody>
              <HStack>
                <Box
                  role="button"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  w="50px"
                  h="50px"
                  bg="#CE445C"
                  borderRadius="100%"
                  color="white"
                >
                  {getInitialName().initial}
                </Box>
                <Box marginLeft="10px !important">
                  <Text fontSize="xs">Club ebet</Text>
                  <Text fontSize="sm" fontWeight="bold">
                    {getInitialName().fullName}
                  </Text>
                  <Text fontSize="xs" color="#CE445C">
                    {user?.email ?? " "}
                  </Text>
                </Box>
              </HStack>
              <Divider my={3} />
              <Button
                color="#CE445C"
                variant="ghost"
                fontSize="sm"
                onClick={onLogout}
              >
                Sign Out
              </Button>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </HStack>
  );
};
