import React from "react";
import { Form } from "formik";
import { VStack } from "@chakra-ui/react";

export const FormStack = ({ children }: { children: React.ReactNode }) => (
  <VStack
    as={Form}
    width="100%"
    spacing="4"
    alignItems="start"
    autoComplete="off"
  >
    {children}
  </VStack>
);
