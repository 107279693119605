import React from "react";
import { render } from "react-dom";
import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import { App } from "./App";
import "../src/styles/global.css";
import "react-quill/dist/quill.snow.css";

// Import app polyfills
import "core-js/stable";

// Configure sentry
if (SENTRY_DSN !== "" && SENTRY_ENV !== "") {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new CaptureConsole({ levels: ["error"] })],
    environment: SENTRY_ENV,
  });
}

const container = document.createElement("div");
container.setAttribute("id", "container");
document.body.appendChild(container);
render(<App />, container);
