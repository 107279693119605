import { useDisclosure } from "@chakra-ui/hooks";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, Flex, VStack } from "@chakra-ui/layout";
import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/modal";
import { IconButton } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLayoutContext } from "../../context/LayoutContext";

const LayoutChild = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};

interface LayoutProps {
  location: string;
  children: [JSX.Element, JSX.Element, JSX.Element];
}

const TitleContainer = ({ children }: { children: React.ReactNode }) => (
  <Box flex="1">{children}</Box>
);

/**
 * A responsive layout that handles a collapsing menu at mobile resolutions.
 */
export const LayoutV2 = ({ children, location }: LayoutProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });

  const { isDesktop } = useLayoutContext();

  const [titleContent, menuContent, content] = children;

  // Close the menu on navigate
  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <>
      {!isDesktop && (
        <Drawer isOpen={isOpen} placement="left" onClose={onClose} size="xs">
          <DrawerOverlay />
          <DrawerContent backgroundColor="cherryUi.800">
            {menuContent}
          </DrawerContent>
        </Drawer>
      )}
      <VStack
        w="100%"
        margin="0 auto"
        padding="0"
        spacing="0"
        minHeight="100vh"
      >
        <Flex w="100%" alignItems="center" shadow="md" zIndex="3" id="topbar">
          {/* Show a hamburger menu button on mobile */}
          {!isDesktop && (
            <IconButton
              onClick={onOpen}
              icon={<HamburgerIcon color="#fff" w="6" h="6" />}
              aria-label="Menu"
              colorScheme="brand"
              color="#fff"
            />
          )}
          <TitleContainer>{titleContent}</TitleContainer>
        </Flex>

        <Flex
          w="100%"
          zIndex="2"
          maxW="100vw"
          flex="1"
          bgColor="transparent"
          overflow="auto"
          height="100%"
        >
          {isDesktop && (
            <Box
              w="112px"
              marginTop="0px"
              flexShrink={0}
              bgColor="#181419"
              id="nav"
            >
              {menuContent}
            </Box>
          )}
          <Box flex="1" overflow="hidden" id="content">
            {content}
          </Box>
        </Flex>
        <Box
          zIndex="0"
          position="absolute"
          w="100%"
          overflowX="hidden"
          top="80px"
          left="112px"
        >
          {/* <Image src={circlesBg} width="100%" minWidth="1200px" /> */}
        </Box>
      </VStack>
    </>
  );
};

LayoutV2.Title = LayoutChild;
LayoutV2.Menu = LayoutChild;
LayoutV2.Content = LayoutChild;
