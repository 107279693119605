import { Box, Heading, VStack } from "@chakra-ui/layout";
import React from "react";
import { useLayoutContext } from "../../context/LayoutContext";

interface PageHeadingProps {
  children?: React.ReactNode;
}

const Title = ({ children }: { children: React.ReactNode }) => {
  return (
    <Heading as="h1" width="100%" className="page-title">
      {children}
    </Heading>
  );
};

const SubTitle = ({ children }: { children: React.ReactNode }) => {
  return <Box>{children}</Box>;
};

export const PageHeadingV3 = ({ children }: PageHeadingProps) => {
  const { isDesktop } = useLayoutContext();

  const paddingProps = isDesktop
    ? { paddingLeft: "40px", paddingRight: "40px" }
    : { paddingLeft: "2", paddingRight: "2" };

  return (
    <VStack
      {...paddingProps}
      spacing="1"
      paddingTop="6"
      paddingBottom="4"
      alignItems="start"
      color="white"
      minH="190px"
      bgColor="#C7C8C7"
    >
      {children}
    </VStack>
  );
};

PageHeadingV3.Title = Title;
PageHeadingV3.SubTitle = SubTitle;
