import React, { useCallback, useEffect, useState } from "react";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { PageContent } from "../../components/PageContent/PageContent";
import {
  Box,
  Button,
  HStack,
  SkeletonText,
  Table,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VStack,
  Link as ChakraLink,
  Text,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Card } from "../../components/Card/Card";
import { PlusIcon } from "../../styles/icons";
import { useModalCloseEffect } from "../../hooks/useModalCloseEffect";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { usePaginatedAdminApiRequest } from "../../hooks/usePaginatedApiRequest";
import { NewBusinessModal } from "../../components/Business/NewBusinessModal";
import { Link, useNavigate } from "react-router-dom";
import { CopyText } from "../../components/Utilities/CopyText";
import { useSearchInput } from "../../hooks/useSearchInput";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useProfile } from "../../context/ProfileContext";
import { isFinite } from "lodash-es";
import BusinessUtil from "../../util/BusinessUtil";

export const Settings = () => {
  const { debouncedQuery, element: searchInputElement } = useSearchInput({
    placeholder: "Search Setting...",
  });

  const newBusinessModal = useDisclosure();
  const profileContext = useProfile();
  const navigate = useNavigate();
  const apiClient = useAdminApiClient();
  const [businesses, setBusinesses] = useState<any>([]);
  const [currentEdits, setCurrentEdits] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const refresh = () => {
    load();
  };

  // Refresh the business list when the modal is closed.
  useModalCloseEffect(newBusinessModal, () => refresh(), [refresh]);

  const load = async () => {
    const allBusinesses = await apiClient.getAllSettings({
      label: debouncedQuery,
      order: "idx",
    });
    setBusinesses(allBusinesses.data.items);
    setIsLoading(false);
  };

  const getDefaultValue = (curr: any) => {
    if (curr.identifier === "delay_funds_available_post_deposit") {
      const parentValue = curr.meta?.parent?.value;
      const def = parentValue === undefined ? 0 : parentValue || 0;
      return `Not set, using default: <u>${def} second(s)</u>`;
    }
    if (curr.identifier.indexOf("gamble_aware") > -1) {
      const parentValue = curr.meta?.parent?.value;
      return parentValue
        ? `Not set, using default: <u>${parentValue}</u>`
        : `Not set`;
    }
    return "";
  };

  const getValidationError = (
    curr: any,
    value: string | undefined,
    isInline = true
  ) => {
    if (!value) return null;

    if (curr.identifier === "delay_funds_available_post_deposit") {
      const intValue = Number.parseInt(value);
      const isNumeric = /^\d+$/.test(value);
      if (!isNumeric || intValue < 0) {
        return "Incorrect value. Should be a positive number.";
      }
      if (intValue > 99999) {
        return "Maximum 5 digits only.";
      }
      return null;
    }

    // gamble aware validations
    if (!isInline && curr.identifier.indexOf("gamble_aware") > -1) {
      if (curr.identifier.indexOf("text_link_gamble_aware") > -1) {
        // is Link->Text
        const isValid = value.length <= 70;
        if (!isValid) {
          return "Incorrect value. Maximum 70 characters.";
        }
      } else if (curr.identifier.indexOf("link_gamble_aware") === 0) {
        // is Link->Link
        const isValid = BusinessUtil.isValidUrl(value);
        if (!isValid) {
          return "Incorrect value. Should be a valid URL.";
        }
      }
    }
    return null;
  };

  const setCurrentEdit = useCallback(
    (identifier: string, value?: string) => {
      const curr = businesses.find((b: any) => b.identifier === identifier);
      const validationError = getValidationError(curr, value);
      if (validationError) {
        // alert(validationError);
        return;
      }
      setCurrentEdits({
        ...currentEdits,
        [identifier]: value !== undefined ? value : curr.value,
      });
    },
    [currentEdits, businesses]
  );

  const saveSetting = useCallback(
    async (identifier: string) => {
      const curr = {
        ...businesses.find((b: any) => b.identifier === identifier),
      };
      curr.value = currentEdits[identifier];
      curr.value =
        curr.value && curr.value.trim ? curr.value.trim() : curr.value;
      const validationError = getValidationError(curr, curr.value, false);
      if (validationError) {
        alert(validationError);
        return;
      }
      cancelEdit(identifier);
      await apiClient.saveSetting(curr);
      load();
    },
    [currentEdits, businesses]
  );

  const cancelEdit = useCallback(
    (identifier: string) => {
      setCurrentEdits({
        ...currentEdits,
        [identifier]: undefined,
      });
    },
    [currentEdits]
  );

  useEffect(() => {
    load();
  }, [debouncedQuery]);

  const skeletonLoading = (rows: number = 12) => {
    return (
      <HStack width="100%" boxShadow="lg">
        <Box width="15%" padding="4" pr="0">
          <SkeletonText mt="4" noOfLines={rows} spacing="4" />
        </Box>
        <Box width="75%" padding="4" pr="0">
          <SkeletonText mt="4" noOfLines={rows} spacing="4" />
        </Box>
        <Box width="10%" pt="4" pb="4">
          <SkeletonText mt="4" noOfLines={rows} spacing="4" />
        </Box>
      </HStack>
    );
  };

  const skeletonLoadingTable = (rows: number = 12) => {
    return (
      <Tr>
        <Td width="15%">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="75%">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
        <Td width="10%" whiteSpace="nowrap">
          <SkeletonText noOfLines={12} spacing="4" width="100%" />
        </Td>
      </Tr>
    );
  };

  return (
    <>
      <PageHeading>
        <PageHeading.Title>Settings</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <VStack width="100%" spacing="4" alignItems="start">
            <HStack width="100%" justifyItems="start">
              <Box flex="1">
                <Box width="100%">{searchInputElement}</Box>
              </Box>
            </HStack>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th w="30%">Name</Th>
                  <Th>Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                {isLoading && skeletonLoadingTable()}
                {!isLoading && businesses && businesses.length === 0 && (
                  <Tr>
                    <Td colSpan={4} textAlign="center">
                      No settings found
                    </Td>
                  </Tr>
                )}
                {businesses &&
                  businesses.length > 0 &&
                  !isLoading &&
                  businesses?.map((business: any) => (
                    <Tr key={business._id}>
                      <Td>
                        <VStack alignItems="start">
                          <Text fontSize="sm" fontWeight="bold">
                            {business.label}
                          </Text>
                          <Text fontSize="sm">{business.description}</Text>
                        </VStack>
                      </Td>
                      <Td w="50%" whiteSpace="nowrap">
                        {currentEdits[business.identifier] === undefined && (
                          <Text
                            fontSize="sm"
                            color="cherryUi.600"
                            cursor="pointer"
                            whiteSpace="normal"
                            onClick={() => setCurrentEdit(business.identifier)}
                            dangerouslySetInnerHTML={{
                              __html:
                                business.value === ""
                                  ? getDefaultValue(business)
                                  : business.value,
                            }}
                          ></Text>
                        )}

                        {currentEdits[business.identifier] !== undefined && (
                          <HStack>
                            <FormControl w="100%">
                              <Input
                                w="100%"
                                type="text"
                                placeholder=""
                                onChange={(e) =>
                                  setCurrentEdit(
                                    business.identifier,
                                    e.target.value
                                  )
                                }
                                value={currentEdits[business.identifier]}
                              />
                            </FormControl>

                            <Box>
                              <Button
                                colorScheme="cherryButton"
                                type="submit"
                                onClick={() => saveSetting(business.identifier)}
                              >
                                Save
                              </Button>
                            </Box>

                            <Box>
                              <Button
                                onClick={() => cancelEdit(business.identifier)}
                              >
                                Cancel
                              </Button>
                            </Box>
                          </HStack>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </VStack>
          <Box mt={8} textAlign="center">
            <Text fontSize="12px" fontFamily="monospace">
              Version v0.20
            </Text>
          </Box>
        </Card>
        {error != null && (
          <ErrorMessage>
            An error was encountered while searching businesses.
          </ErrorMessage>
        )}
      </PageContent>
      <NewBusinessModal {...newBusinessModal} />
    </>
  );
};
