import { Button } from "@chakra-ui/button";
import { Box, VStack } from "@chakra-ui/layout";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "../../../assets/Home_Business.svg";
import ReportIcon from "../../../assets/Reports.svg";
import SessionIcon from "../../../assets/Sessions.svg";
import SettingIcon from "../../../assets/Settings.svg";
import { useProfile } from "../../context/ProfileContext";

export interface MenuItemPropsV2 {
  title: string;
  target: string;
  icon: string;
  children?: React.ReactNode;
  isActive?: boolean;
}

export const DesktopSubMenuItemV2 = ({
  title,
  target,
  icon,
}: MenuItemPropsV2) => (
  <AccordionPanel pb={4} p="0">
    <Button
      as={Link}
      to={target}
      w="100%"
      height="50px"
      justifyContent="start"
      paddingTop="4"
      paddingBottom="4"
      paddingLeft="4"
      paddingRight="8"
      shadow="md"
      fontWeight="600"
      borderRadius="none"
      fontSize="sm"
      color="cherryUi.800"
    >
      <Box as="span" display="block" color="cherryUi.800" fontSize="sm" mr={2}>
        <img src={icon} alt="" width={20} height={20} />
      </Box>
      {title}
    </Button>
  </AccordionPanel>
);

export const DesktopMenuItemV2 = ({
  children,
  title,
  target,
  icon,
  isActive,
}: MenuItemPropsV2) => (
  <Box
    bg={isActive ? "#332C39" : "none"}
    height="96px"
    m={0}
    p={4}
    pr={0}
    pl={0}
    textAlign="center"
    _hover={{ bgColor: "#332C39" }}
  >
    <Button
      as={Link}
      to={target}
      borderRadius="none"
      flex="1"
      textAlign="center"
      background="none"
      height="auto"
      color="white"
      display="block"
      justifyContent="center"
      padding="0"
      _hover={{ bgColor: "transparent" }}
      _focus={{ bgColor: "transparent" }}
      _active={{ bgColor: "none" }}
    >
      <VStack>
        <Box
          display="block"
          color="white"
          fontSize="sm"
          m={0}
          p={0}
          pt={1}
          margin="auto"
        >
          <img src={icon} alt="" height={30} width={30} />
        </Box>
        <Box sx={{ fontSize: 13 }}>{title}</Box>
      </VStack>
    </Button>
  </Box>
);

export const MobileSubMenuItemV2 = ({
  title,
  target,
  icon,
}: MenuItemPropsV2) => (
  <AccordionPanel pb={4} p="0" border="none">
    <Button
      as={Link}
      to={target}
      w="100%"
      height="50px"
      justifyContent="start"
      paddingTop="4"
      paddingBottom="4"
      paddingLeft="4"
      paddingRight="8"
      shadow="md"
      fontWeight="600"
      borderRadius="none"
      fontSize="sm"
      color="cherryUi.800"
    >
      <Box as="span" display="block" color="cherryUi.800" fontSize="sm" mr={2}>
        <img src={icon} alt="" />
      </Box>
      {title}
    </Button>
  </AccordionPanel>
);

export const MobileMenuItemV2 = ({
  children,
  title,
  target,
  icon,
}: MenuItemPropsV2) => (
  <AccordionItem bgColor="cherryUi.700">
    <h2>
      <AccordionButton>
        <Button
          as={Link}
          to={target}
          borderRadius="none"
          flex="1"
          textAlign="left"
          background="none"
          color="white"
          justifyContent="left"
          _hover={{ bgColor: "transparent" }}
        >
          <Box as="span" display="block" color="white" fontSize="sm" mr={2}>
            {icon}
          </Box>
          {title}
        </Button>
        {children && <AccordionIcon color="white" />}
      </AccordionButton>
    </h2>
    {children}
  </AccordionItem>
);

export const HomeMenuV2 = ({ isDesktop }: { isDesktop: boolean }) => {
  const MenuItem = isDesktop ? DesktopMenuItemV2 : MobileMenuItemV2;
  const SubMenuItem = isDesktop ? DesktopSubMenuItemV2 : MobileSubMenuItemV2;
  const profileContext = useProfile();
  const location = useLocation();
  const path = location.pathname;

  return (
    <Box w="100%" h="96px" pt={50} bg="#181419">
      <MenuItem
        target={`/`}
        icon={HomeIcon}
        title="HOME"
        isActive={path === "/"}
      />

      {profileContext.isWorldAdmin && (
        <>
          <MenuItem
            target={`/reports`}
            icon={ReportIcon}
            title="REPORTS"
            isActive={path.includes("/reports")}
          />
          <MenuItem
            target={`/settings`}
            icon={SettingIcon}
            title="SETTINGS"
            isActive={path.includes("/settings")}
          />
          <MenuItem
            target={`/sessions`}
            icon={SessionIcon}
            title="SESSIONS"
            isActive={path.includes("/session")}
          />
        </>
      )}
    </Box>
  );
};
