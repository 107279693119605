import React, { useCallback, useState } from "react";
import { useDebouncedValue } from "./useDebouncedValue";
import {
  SearchInput,
  SearchInputProps,
} from "../components/SearchInput/SearchInput";
import { InputProps } from "@chakra-ui/react";

export const useSearchInput = (
  props?: {
    defaultValue?: string;
    waitMs?: number;
  } & InputProps &
    SearchInputProps
) => {
  const [query, setQuery] = useState(props?.defaultValue ?? "");
  const debouncedQuery = useDebouncedValue(query, props?.waitMs ?? 400);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.currentTarget.value),
    [setQuery]
  );

  const element = (
    <SearchInput
      value={query}
      onChange={onChange}
      isDebouncing={query !== debouncedQuery}
      placeholder="Type to search ..."
      setQuery={setQuery}
      {...props}
    />
  );

  return {
    query,
    debouncedQuery,
    onChange,
    setQuery,
    isDebouncing: query !== debouncedQuery,
    element,
  };
};
