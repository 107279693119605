type ResultPayload<T> = {
  type: "success" | "fail";
  data?: T;
  message?: string;
  errors?: any;
};

export class Result<T> {
  isSuccess: boolean;
  data?: T;
  message?: string;
  errors?: any;

  constructor(payload: ResultPayload<T>) {
    this.isSuccess = payload.type === "success";
    this.data = payload.data;
    this.message = payload.message;
    this.errors = payload.errors;
  }
}
