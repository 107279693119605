import { Box, HStack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { getKYCSummary } from "../../../api/firebaseService";
import { LoadingIndicator } from "../../../components/LoadingIndicator/LoadingIndicator";
import StatisticCard from "../../../components/StatisticCard/StatisticCard";
import { useBusiness } from "../../../context/BusinessContext";
import { useFirebase } from "../../../context/FirebaseContext";
import { useAdminApiClient } from "../../../hooks/useApiClient";
import DateUtil from "../../../util/DateUtil";

export const Statistic = () => {
  const contextBusiness = useBusiness();
  const { db } = useFirebase();
  const apiClient = useAdminApiClient();
  const [isLoading, setIsLoading] = useState(true);
  const [statistic, setStatistic] = useState({
    kyc: 0,
    cardit: 0,
    mandates: 0,
    transactions: 0,
  });

  const last7Days = new Date(new Date().setDate(new Date().getDate() - 7));
  const last30Days = new Date(new Date().setDate(new Date().getDate() - 30));
  const formattedLast7Days = DateUtil.getFormattedDate(last7Days, "yyyy-MM-dd");
  const formattedLast30Days = DateUtil.getFormattedDate(
    last30Days,
    "yyyy-MM-dd"
  );
  const businessId = contextBusiness?.srcBusiness?.id;
  const business_id = contextBusiness?._id;

  const getCardItStatisticData = async () => {
    const res = await apiClient.getCardItEnableSummary({
      startDate: formattedLast30Days,
      business_id,
    });
    if (res.isSuccess) {
      setStatistic((prev) => ({ ...prev, cardit: res.data as number }));
    }
  };

  const getKycStatisticData = async () => {
    const res = await getKYCSummary(db, formattedLast30Days, businessId);
    if (res.isSuccess) {
      setStatistic((prev) => ({ ...prev, kyc: res.data as number }));
    }
  };

  const getMandateStatisticData = async () => {
    const res = await apiClient.getMandateSummary({
      startDate: formattedLast30Days,
      businessId,
    });
    if (res.isSuccess) {
      setStatistic((prev) => ({ ...prev, mandates: res.data as number }));
    }
  };

  const getTransactionStatisticData = async () => {
    const res = await apiClient.getTransactionSummary({
      startDate: formattedLast7Days,
      businessId,
    });
    if (res.isSuccess) {
      setStatistic((prev) => ({ ...prev, transactions: res.data as number }));
    }
  };

  const populateStatisticData = async () => {
    await Promise.all([
      getCardItStatisticData(),
      getKycStatisticData(),
      getMandateStatisticData(),
      getTransactionStatisticData(),
    ]);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!!contextBusiness?.srcBusiness?.id) {
      populateStatisticData();
    }
  }, [contextBusiness]);

  if (!contextBusiness || isLoading) {
    return (
      <Box>
        <LoadingIndicator color="cherryUi.600" />
      </Box>
    );
  }

  return (
    <HStack w="100%" alignItems="start" marginTop="-70px" spacing="10">
      <StatisticCard
        value={statistic.cardit}
        label="CARDIT+ ENABLED"
        subLabel="In last 30 days"
      />
      <StatisticCard
        value={statistic.kyc}
        label="KYC CHECK"
        subLabel="In last 30 days"
      />
      <StatisticCard
        value={statistic.mandates}
        label="MANDATES CREATED"
        subLabel="In last 30 days"
      />
      <StatisticCard
        value={statistic.transactions}
        label="TRANSACTIONS"
        subLabel="In last 7 days"
      />
    </HStack>
  );
};
