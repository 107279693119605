import { useUserContext } from "../context/UserContext";

export const useDataApiClient = () => {
  const { dataApiClient } = useUserContext();

  if (!dataApiClient) {
    throw new Error("Cannot use data api client without a user context.");
  }

  return dataApiClient;
};

export const useAdminApiClient = () => {
  const { adminApiClient: adminApiClient } = useUserContext();

  if (!adminApiClient) {
    throw new Error("Cannot use admin api client without a user context.");
  }

  return adminApiClient;
};
