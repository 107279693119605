import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { useVenue } from "../../../context/VenueContext";
import Geosuggest from "react-geosuggest";
import { EditButton } from "../../../components/Shared/EditButton";
import { SaveButton } from "../../../components/Shared/SaveButton";
import { CancelButton } from "../../../components/Shared/CancelButton";
import { useAdminApiClient } from "../../../hooks/useApiClient";
import { useToast } from "../../../hooks/useToast";
import { isEmptyStr } from "../../../util/stringHelper";
import { isValidEmail } from "../../../util/isValidEmail";
import { isValidPhone } from "../../../util/isValidPhone";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";

const VenueDetailSection = () => {
  const apiClient = useAdminApiClient();
  const { venue } = useVenue();
  const { showSuccessToast, showErrorToast } = useToast();
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [isEditContact, setIsEditContact] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [validationError, setValidationError] = useState<string>("");

  const updateForm = useCallback(
    (newData) => {
      setFormData({ ...formData, ...newData });
    },
    [formData]
  );

  const onSuggestSelect = (suggest: any) => {
    if (!suggest) {
      return;
    }
    updateForm({
      address: suggest,
    });
  };

  const editAddress = () => {
    updateForm({
      address: undefined,
    });
    setIsEditAddress(true);
  };

  const cancelEditAddress = () => {
    if (!!venue) {
      updateForm({
        address: venue?.address,
      });
    }
    setIsEditAddress(false);
  };

  const editContact = () => {
    setIsEditContact(true);
  };

  const cancelEditContact = () => {
    if (!!venue) {
      updateForm({
        contactName: venue?.contactName,
        contactEmail: venue?.contactEmail,
        contactPhone: venue?.contactPhone,
      });
    }
    setValidationError("");
    setIsEditContact(false);
  };

  const submitAddress = async () => {
    try {
      setIsSubmitting(true);
      await apiClient.modifyVenue(venue?._id, { address: formData.address });
      setIsSubmitting(false);
      setIsEditAddress(false);
      showSuccessToast("Venue successfully updated.");
    } catch (e) {
      setIsSubmitting(false);
      setIsEditAddress(false);
      showErrorToast("Failed to update the venue.");
      console.log(e);
    }
  };

  const submitContact = async () => {
    try {
      setIsSubmitting(true);
      let errorMessage = "";
      setValidationError(errorMessage);
      if (isEmptyStr(formData.contactName) || formData.contactName.length < 2) {
        errorMessage = "Please enter a valid name";
      }
      const isValidContactEmail = isValidEmail(formData.contactEmail);
      if (!isValidContactEmail) {
        errorMessage = "Please enter a valid email address";
      }
      const isValidContactPhone = isValidPhone(formData.contactPhone);
      if (!isValidContactPhone) {
        errorMessage = "Please enter a valid phone number";
      }

      if (errorMessage !== "") {
        setValidationError(errorMessage);
        setIsSubmitting(false);
        return;
      }

      const newContact = {
        contactName: formData.contactName,
        contactEmail: formData.contactEmail,
        contactPhone: formData.contactPhone,
      };
      await apiClient.modifyVenue(venue?._id, newContact);
      updateForm(newContact);
      setIsSubmitting(false);
      setIsEditContact(false);
      showSuccessToast("Venue successfully updated.");
    } catch (e) {
      setIsSubmitting(false);
      setIsEditContact(false);
      showErrorToast("Failed to update the venue.");
      console.log(e);
    }
  };

  useEffect(() => {
    if (!!venue) {
      updateForm({
        address: venue?.address,
        contactName: venue?.contactName,
        contactEmail: venue?.contactEmail,
        contactPhone: venue?.contactPhone,
      });
    }
  }, [venue]);

  return (
    <>
      {/* Address region */}
      {isEditAddress ? (
        <>
          <Text as="h2" className="page-section-title" mb="14px">
            SELECT ADDRESS
          </Text>
          <Box width="100%">
            <Geosuggest
              placeholder="E.g. 23, Mulburry St"
              width="100%"
              onSuggestSelect={onSuggestSelect}
            />
          </Box>
          <Box mt="24px">
            <HStack spacing={4}>
              <SaveButton
                isLoading={isSubmitting}
                disable={!formData.address || isSubmitting}
                onClick={submitAddress}
              />
              <CancelButton onClick={cancelEditAddress} />
            </HStack>
          </Box>
        </>
      ) : (
        <>
          <Text as="h2" className="page-section-title" mb="14px">
            ADDRESS
          </Text>
          <Text className="page-section-content" fontWeight="bold !important">
            {venue?.title}
          </Text>
          <Text className="page-section-content">
            {formData?.address?.gmaps?.name}
          </Text>
          <Text className="page-section-content">
            {formData?.address?.gmaps?.formatted_address}
          </Text>
          <Box mt="10px">
            <EditButton onClick={editAddress} />
          </Box>
        </>
      )}

      {/* Contact region */}
      {isEditContact ? (
        <>
          <Text as="h2" className="page-section-title" mb="14px" mt="34px">
            EDIT CONTACT DETAILS
          </Text>
          <Box px={5} py={3} border="1px dashed #ccc" borderRadius="5px">
            <FormControl w="100%">
              <FormLabel>Name</FormLabel>
              <Input
                bg="#fff"
                w="100%"
                type="text"
                placeholder=""
                onChange={(e) => updateForm({ contactName: e.target.value })}
                value={formData.contactName}
              />
            </FormControl>

            <FormControl w="100%" mt={5}>
              <FormLabel>Email</FormLabel>
              <Input
                bg="#fff"
                w="100%"
                type="text"
                placeholder=""
                onChange={(e) => updateForm({ contactEmail: e.target.value })}
                value={formData.contactEmail}
              />
            </FormControl>

            <FormControl w="100%" mt={5}>
              <FormLabel>Phone</FormLabel>
              <Input
                bg="#fff"
                w="100%"
                type="text"
                placeholder=""
                onChange={(e) => updateForm({ contactPhone: e.target.value })}
                value={formData.contactPhone}
              />
            </FormControl>
          </Box>
          <Box mt="24px">
            <HStack spacing={4}>
              <SaveButton
                isLoading={isSubmitting}
                disable={isSubmitting}
                onClick={submitContact}
              />
              <CancelButton onClick={cancelEditContact} />
            </HStack>
          </Box>
        </>
      ) : (
        <>
          <Text as="h2" className="page-section-title" mb="14px" mt="34px">
            CONTACT DETAILS
          </Text>
          <Text className="page-section-content" fontWeight="bold !important">
            {formData?.contactName}
          </Text>
          <Text className="page-section-content">{formData?.contactEmail}</Text>
          <Text className="page-section-content">{formData?.contactPhone}</Text>
          <Box mt="10px">
            <EditButton onClick={editContact} />
          </Box>
        </>
      )}

      {validationError && (
        <Box mt={4}>
          <ErrorMessage>{validationError}</ErrorMessage>
        </Box>
      )}
    </>
  );
};

export default VenueDetailSection;
