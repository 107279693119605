import { Box } from "@chakra-ui/react";
import React from "react";
import BackIcon from "../../../assets/Back.svg";

const RoundedBackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box
      h="66px"
      w="66px"
      bgColor="white"
      borderRadius="50%"
      padding="16px 20px 16px 12px"
      style={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <img src={BackIcon} alt="" style={{ height: "34px", width: "34px" }} />
    </Box>
  );
};

export default RoundedBackButton;
