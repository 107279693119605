import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { doc, getDoc, updateDoc } from "@firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useFirebase } from "../../context/FirebaseContext";
import { useToast } from "../../hooks/useToast";

const Sessions = () => {
  const navigate = useNavigate();
  const { db } = useFirebase();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessToast, showErrorToast } = useToast();

  const resetSessions = async () => {
    setIsLoading(true);
    try {
      const docRef = doc(db, `/users/sessions/${phoneNumber}/status`);
      const currentDoc = await getDoc(docRef);
      if (currentDoc.exists()) {
        const currentSession = currentDoc.data();
        const newSession = {
          ...currentSession,
          isLoggedIn: false,
        };
        await updateDoc(docRef, newSession);
        showSuccessToast("Session has been reset successfully.");
        setIsLoading(false);
      } else {
        showErrorToast("No session associated with the given phone number!");
        setIsLoading(false);
      }
    } catch (error) {
      showErrorToast(
        "An error occurred while resetting the session, please try again."
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageHeading>
        <PageHeading.Title>Reset Sessions</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%" px={10} bgColor="white">
          <Text fontSize="md" mb={5}>
            Please input the phone number to reset the session.
          </Text>
          <FormControl w="100%">
            <FormLabel>Phone Number</FormLabel>
            <Input
              w="100%"
              type="text"
              placeholder="+61xxxxxxxx"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </FormControl>

          <HStack mt={10}>
            <Button onClick={() => navigate(-1)}>Cancel</Button>
            <Button
              colorScheme="cherryButton"
              onClick={resetSessions}
              isLoading={isLoading}
              disabled={phoneNumber === ""}
            >
              Reset
            </Button>
          </HStack>
        </Card>
      </PageContent>
    </>
  );
};

export default Sessions;
