import React, { useContext, useEffect, useMemo, useState } from "react";
import { createContext } from "react";
import { useParams } from "react-router";
import { CherryHubAdminApi } from "../api/adminApiModels";
import { ErrorMessage } from "../components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { ApiRequestState, useAdminApiRequest } from "../hooks/useApiRequest";
import { useAdminApiClient } from "../hooks/useApiClient";

interface TModelContext {
  business: ApiRequestState<CherryHubAdminApi.Business | null>;
}

const ModelContext = createContext<TModelContext | null>(null);

/**
 * Loads models from the api based on the presence of react-router path parameters. Example: if
 * the `businessId` path parameter is set, this component will display loading & error states and
 * then inject the business into the context once loaded.
 */
export const ModelContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const apiClient = useAdminApiClient();
  const { businessId } = useParams();
  const [business, setBusiness] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const loadBusiness = async () => {
    setIsLoading(true);
    if (!businessId) {
      setIsLoading(false);
      return null;
    }
    const singleBusiness = await apiClient.getSingleBusiness(
      businessId?.toString() ?? ""
    );
    setBusiness(singleBusiness);
    setIsLoading(false);
  };

  useEffect(() => {
    // loadBusiness();
    setIsLoading(false);
  }, [businessId]);

  const context = useMemo(
    () => ({
      business: business,
    }),
    [business]
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return (
      <ErrorMessage>
        An error was encounterd while loading the page
      </ErrorMessage>
    );
  }

  return (
    <ModelContext.Provider value={context}>{children}</ModelContext.Provider>
  );
};

/**
 * Returns the business loaded from the `:businessId` path parameter
 */
export const useBusinessContext = (): CherryHubAdminApi.Business => {
  const ctx = useContext(ModelContext);

  if (!ctx || !ctx.business.data) {
    throw new Error("Missing business context");
  }

  return ctx.business.data;
};
